import React, { useCallback, useState, useEffect, useMemo, useContext } from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    WizardPage,
    wizardProps
} from '@xengage/gw-portals-wizard-react';
import { WizardPageTemplate } from 'gw-portals-wizard-components-ui';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { ClaimUtil } from 'gwce-capability-fnol-common-react-ext';
import metadata from './FNOLHOIncidentDetails.metadata.json5';
import PropertyIncidents from './PropertyIncidents/PropertyIncidents';
import InjuryIncidents from './InjuryIncidents/InjuryIncidents';
import VehicleIncidents from './VehicleIncidents/VehicleIncidents';
import SelectAddressComponent from './LossLocation/SelectAddressComponent';
import LossLocationComponent from './LossLocation/LossLocationComponent';

function FNOLHOIncidentDetails(props) {
    const {
        wizardData,
        wizardSnapshot,
        updateWizardData,
        history: {
            location: { state = {} }
        }
    } = props;
    const { FNOLService } = useDependencies('FNOLService');
    const { authHeader } = useAuthentication();
    const [isLoading, setIsLoading] = useState(false);
    const [pageData] = useState(state);
    const [showErrors, setShowErrors] = useState(false)
    const claimVM = !_.isEmpty(wizardData) ? wizardData : wizardSnapshot;
    const {
        onValidate,
        isComponentValid,
        initialValidation,
        registerInitialComponentValidation
    } = useValidation(
        'FNOLHOIncidentDetailsPage'
    );
    const viewModelService = useContext(ViewModelServiceContext);
    const LOB_NAME = ClaimUtil.getLobByPolicyType(_.get(claimVM, 'value.policy.policyType', 'homeowners'));
    
    const propertyIncidentsPath = `lobs.${LOB_NAME}.fixedPropertyIncidents_Ext`
    const injuryIncidentsPath = `lobs.${LOB_NAME}.injuryIncidents_Ext`
    const vehicleIncidentsPath = `lobs.${LOB_NAME}.vehicleIncidents_Ext`
    const relatedContactsPath = `lobs.${LOB_NAME}.relatedContacts_Ext`
    const contactsWithoutAgentUWPath = `lobs.${LOB_NAME}.contactsWithoutAgentUW_Ext`
    const ownerContactsPath = `lobs.${LOB_NAME}.ownerContacts_Ext`

    const lossSubCauseAvailableValues = _.get(claimVM, 'lossSubCause_Ext.aspects.availableValues', [])

    const propertyIncidents = _.get(claimVM.value, propertyIncidentsPath, [])
    const injuryIncidents = _.get(claimVM.value, injuryIncidentsPath, [])
    const vehicleIncidents = _.get(claimVM.value, vehicleIncidentsPath, [])

    const relatedContacts = _.get(claimVM.value, relatedContactsPath, [])
    const contactsWithoutAgentUW = _.get(claimVM.value, contactsWithoutAgentUWPath, []);
    const ownerContacts = _.get(claimVM.value, ownerContactsPath, []);

    const predefinedLossLocations = _.get(claimVM.value, 'predefinedLossLocations', []);
    const incidentsAddresses = _.get(claimVM.value, 'incidentsAddresses', [])
    const initiallossLocationIndex = predefinedLossLocations.findIndex((location) => {
        return location.publicID === _.get(claimVM, 'value.lossLocation.publicID')
    })
    const [lossLocationIndex, updateLossLocationIndex] = useState(initiallossLocationIndex);
    const [countNewLocation, updateCountNewLocation] = useState(0);
    const primaryLocationID = _.get(claimVM, 'value.primaryLocation.publicID');
    const currentLossLocation = predefinedLossLocations[lossLocationIndex];
    const isLocationReadOnly = primaryLocationID === _.get(currentLossLocation, 'publicID');

    const onNext = useCallback(async () => {
        setIsLoading(true)
        const updatedClaim = await FNOLService.saveFNOLDetails(claimVM.value, authHeader)
        setIsLoading(false)
        const updatedClaimVM = viewModelService.clone(claimVM)
        updatedClaimVM.value = updatedClaim
        updateWizardData(updatedClaimVM)
        return updatedClaimVM
    }, [FNOLService, authHeader, claimVM, updateWizardData, viewModelService]);

    const setValidation = () => {
        setShowErrors(true)
        return false
    }

    const isClaimStatus = useCallback(() => {
        const { claimStatus } = pageData;
        return !_.isEmpty(claimStatus);
    }, [pageData]);

    useEffect(() => {
        registerInitialComponentValidation(isClaimStatus);
    }, [registerInitialComponentValidation, isClaimStatus]);

    const updatePropertyIncidents = useCallback((newPropertyIncidents, updatedAddress) => {
        _.set(claimVM.value, propertyIncidentsPath, newPropertyIncidents)

        if (!_.isNil(updatedAddress)) {
            const {
                publicID: updatedAddressID
            } = updatedAddress
            const isExistingClaimAddress = predefinedLossLocations.some((address) => address.publicID === updatedAddressID)
            
            const updatedClaimAddress = isExistingClaimAddress ? predefinedLossLocations.map((address) => {
                if (address.publicID === updatedAddressID) {
                    return updatedAddress
                }
                return address
            }) : predefinedLossLocations.concat([updatedAddress])
            _.set(claimVM.value, 'predefinedLossLocations', updatedClaimAddress)
        }
        updateWizardData(claimVM)
    }, [claimVM, predefinedLossLocations, propertyIncidentsPath, updateWizardData])

    const updateContacts = useCallback((updatedRelatedContact, contactsToUpdate) => {
        if (!_.isNil(updatedRelatedContact)) {
            // add contact
            const {
                publicID: updatedContactID
            } = updatedRelatedContact
            const isExistingContact = contactsToUpdate.some((contact) => contact.publicID === updatedContactID)
    
            const updatedRelatedContacts = isExistingContact ? contactsToUpdate.map((contact) => {
                if (contact.publicID === updatedContactID) {
                    return updatedRelatedContact
                }
                return contact
            }) : contactsToUpdate.concat([updatedRelatedContact])

            return updatedRelatedContacts
        }
    }, [])

    const updateAllContacts = useCallback((newClaimVM, updatedRelatedContact) => {
        const updatedRelatedContacts = updateContacts(updatedRelatedContact, relatedContacts)
        _.set(newClaimVM.value, relatedContactsPath, updatedRelatedContacts)
        const updatedContactsWithoutAgentUW = updateContacts(updatedRelatedContact, contactsWithoutAgentUW)
        _.set(newClaimVM.value, contactsWithoutAgentUWPath, updatedContactsWithoutAgentUW)
        const updatedOwnerContacts = updateContacts(updatedRelatedContact, ownerContacts)
        _.set(newClaimVM.value, ownerContactsPath, updatedOwnerContacts)
    }, [contactsWithoutAgentUW, contactsWithoutAgentUWPath, ownerContacts, ownerContactsPath, relatedContacts, relatedContactsPath, updateContacts])

    const updateInjuryIncidents = useCallback((newInjuryIncidents, updatedRelatedContact) => {
        const newClaimVM = viewModelService.clone(claimVM)
        _.set(newClaimVM.value, injuryIncidentsPath, newInjuryIncidents)
        updateAllContacts(newClaimVM, updatedRelatedContact)
        updateWizardData(newClaimVM)
    }, [claimVM, injuryIncidentsPath, updateAllContacts, updateWizardData, viewModelService])

    const updateVehicleIncidents = useCallback((newVehicleIncidents, updatedRelatedContact, updatedAddress) => {
        const newClaimVM = viewModelService.clone(claimVM)
        _.set(newClaimVM.value, vehicleIncidentsPath, newVehicleIncidents)
        updateAllContacts(newClaimVM, updatedRelatedContact)

        if (!_.isNil(updatedAddress)) {
            const {
                publicID: updatedAddressID
            } = updatedAddress
            const isExistingIncidentAddress = incidentsAddresses.some((address) => address.publicID === updatedAddressID)
            
            const updatedIncidentAddress = isExistingIncidentAddress ? incidentsAddresses.map((address) => {
                if (address.publicID === updatedAddressID) {
                    return updatedAddress
                }
                return address
            }) : incidentsAddresses.concat([updatedAddress])
            _.set(newClaimVM.value, 'incidentsAddresses', updatedIncidentAddress)
        }
        
        updateWizardData(newClaimVM)
    }, [claimVM, incidentsAddresses, updateAllContacts, updateWizardData, vehicleIncidentsPath, viewModelService])

    const onSelectAddressChange = useCallback((value) => {
        const newClaimVM = viewModelService.clone(claimVM);
        if (value=== 'new') {
            updateLossLocationIndex(predefinedLossLocations.length);
            updateCountNewLocation(countNewLocation + 1);
        } else {
            const newLossLocationIndex = predefinedLossLocations.findIndex((location)=>location.publicID === value);
            updateLossLocationIndex(newLossLocationIndex);
            _.set(newClaimVM, 'value.lossLocation', predefinedLossLocations[newLossLocationIndex]);
        }
        updateWizardData(newClaimVM);
    }, [claimVM, countNewLocation, predefinedLossLocations, updateWizardData, viewModelService])

    const onLocationChange = useCallback((value, path) => {
        const newClaimVM = viewModelService.clone(claimVM);
        _.set(newClaimVM, `value.predefinedLossLocations[${lossLocationIndex}].${path}`, value);
        if (_.isNil(_.get(newClaimVM, `value.predefinedLossLocations[${lossLocationIndex}].publicID`))) {
           _.set(newClaimVM, `value.predefinedLossLocations[${lossLocationIndex}].publicID`, `tempNew${countNewLocation}`)
           _.set(newClaimVM, `value.predefinedLossLocations[${lossLocationIndex}].country`, 'US')
        }
        _.set(newClaimVM, 'value.lossLocation', predefinedLossLocations[lossLocationIndex]);
        updateWizardData(newClaimVM);
    }, [claimVM, countNewLocation, lossLocationIndex, predefinedLossLocations, updateWizardData, viewModelService])
    
    const overrideProps = useMemo(() => ({
        incidentDetailsPageLoader: {
            loaded: !isLoading,
        },
        lossSubCauseDropdown: {
            visible: lossSubCauseAvailableValues.length > 0
        },
        propertyIncidents: {
            propertyIncidents: propertyIncidents,
            vehicleIncidents: vehicleIncidents,
            updatePropertyIncidents: updatePropertyIncidents,
            predefinedLossLocations,
            incidentsAddresses,
        },
        injuryIncidents: {
            injuryIncidents: injuryIncidents,
            updateInjuryIncidents: updateInjuryIncidents,
            contactsWithoutAgentUW: contactsWithoutAgentUW
        },
        vehicleIncidents: {
            vehicleIncidents: vehicleIncidents,
            updateVehicleIncidents: updateVehicleIncidents,
            ownerContacts: ownerContacts,
            predefinedLossLocations,
            incidentsAddresses,
        },
        selectAddress: {
            predefinedLossLocations: predefinedLossLocations,
            lossLocationIndex: lossLocationIndex,
            onSelectAddressChange: onSelectAddressChange,
            countNewLocation: countNewLocation
        },
        lossLocation: {
            predefinedLossLocations: predefinedLossLocations,
            lossLocationIndex: lossLocationIndex,
            onLocationChange: onLocationChange,
            isReadOnly: isLocationReadOnly,
            countNewLocation: countNewLocation,
            setComponentValidation: onValidate,
            showErrors: showErrors,
        }
    }), [
        isLoading,
        lossSubCauseAvailableValues.length,
        propertyIncidents,
        updatePropertyIncidents,
        injuryIncidents,
        updateInjuryIncidents,
        contactsWithoutAgentUW,
        ownerContacts,
        vehicleIncidents,
        incidentsAddresses,
        updateVehicleIncidents,
        predefinedLossLocations,
        lossLocationIndex,
        onSelectAddressChange,
        countNewLocation,
        onLocationChange,
        isLocationReadOnly,
        onValidate,
        showErrors
    ]);

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(metadata.pageContent, claimVM, id, path, overrideProps);
        },
        [claimVM, overrideProps]
    );

    const resolvers = {
        resolveValue: readValue,
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            PropertyIncidents: PropertyIncidents,
            InjuryIncidents: InjuryIncidents,
            VehicleIncidents: VehicleIncidents,
            SelectAddressComponent: SelectAddressComponent,
            LossLocationComponent: LossLocationComponent
        }
    };

    return (
        <WizardPage disableNext={!isComponentValid} onNext={isComponentValid ? onNext : setValidation} template={WizardPageTemplate} skipWhen={initialValidation} >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={claimVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                resolveValue={resolvers.resolveValue}
                showErrors={showErrors}
            />
        </WizardPage>
    );
}

FNOLHOIncidentDetails.propTypes = wizardProps;
FNOLHOIncidentDetails.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired
};

export default withRouter(FNOLHOIncidentDetails);
 