import { useTranslator } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { WniAccountsUtil } from 'wni-portals-util-js';
import { useProductsData } from 'wni-portals-util-react';
import PersonalProducts from './PersonalProducts';
import metadata from './ProductsField.metadata.json5';

function ProductsComponent(props) {
    const {
        productSelected = [],
        updateProductSelected,
        productVisible = false,
        useAuthenticationData,
        useDependenciesData,
        accountType,
        baseState,
        disabled,
        showErrors = false,
        validationMessages = [],
        accountProductAvailableMaps, // for CL, when create account was block, the products may need to make available in PC
    } = props;

    const translator = useTranslator();
    const { getAvailableProducts } = useProductsData(useAuthenticationData,useDependenciesData);
    const isCL = WniAccountsUtil.isCLAccount(accountType);
    const [productsList, updateProductList] = useState([]);

    const handleTooltipContent = (product) => {
        const { name, tooltip } = product;
        if(!tooltip) {
            return null;
        }
        return (
            <div className="productTooltip">
                <h6 className="productTipHead">{translator(name)}</h6>
                <div className="productTipContent">{translator(tooltip)}</div>
            </div>
        );
    };

    
    const generateProduct = useCallback((productsData) => {
        if (!isCL) {
            return productsData.map((item) => {
                return {
                    ...item,
                    tooltip: handleTooltipContent(item),
                };
            });
        }
        // for CL, block account, unavailable product
        const newProducts = productsData.map((product) => {
            const findMatch = accountProductAvailableMaps.find((item) => item.productCode === product.accountAvailableCode);
            return {
                ...product,
                tooltip: handleTooltipContent(product),
                disabled: findMatch ? !findMatch.isAvailable : false,
            };
        });
        return newProducts;
    },[accountProductAvailableMaps, isCL]);

    useEffect(() => {
        const productsMap = getAvailableProducts(accountType, baseState);
        const productIdsMap = productsMap.map((item) => item.code);
        const availableProductSelected = _.intersection(
            productSelected,
            productIdsMap
        );
        updateProductSelected(availableProductSelected);

        const newProductsList = _.cloneDeep(productsMap);

        updateProductList(generateProduct(newProductsList));
    }, [baseState]);

    const renderFieldValidation = () => {
        return validationMessages.map((item, index) => <div key={index}>{item}</div>)
    };

    const overrideProps = {
        personalProductsField: {
            disabled,
            visible: !isCL,
            availableValues: productsList,
            value: productSelected,
            onValueChange: updateProductSelected,
        },
        commercialProductsField: {
            className: (showErrors && validationMessages.length > 0) ? 'validationError p-5' : '',
            disabled,
            visible: isCL,
            availableValues: productsList,
            value: productSelected,
            onValueChange: updateProductSelected,
        },
        productMapsError: {
            visible: showErrors,
            content: renderFieldValidation()
        }
    };
    const resolvers = {
        resolveComponentMap: {
            personalproducts: PersonalProducts,
        },
    };
    if (!productVisible) {
        return null;
    }
    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={{ productSelected: productSelected }}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

ProductsComponent.propTypes = {
    productSelected: PropTypes.arrayOf(PropTypes.string),
    updateProductSelected: PropTypes.func.isRequired,
    productVisible: PropTypes.bool,
};
ProductsComponent.defaultProps = {
    productVisible: false,
};

export default ProductsComponent;