import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { FNOLGLLossTypePage } from 'gwce-capability-fnol-gl-react-ext';
import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';

function FNOLCRLossTypePage(props) {
    return <FNOLGLLossTypePage 
                {...props} 
                showLossType={false}
            />;
}

FNOLCRLossTypePage.propTypes = wizardProps;
FNOLCRLossTypePage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func,
    }).isRequired,
};
export default withRouter(withAuthenticationContext(FNOLCRLossTypePage));
