import { Icon } from '@jutro/components';
import { Flex } from '@jutro/layout';
import React from 'react';

const DEFAULT_TYPE = 'warning';

const ISSUES_CONTAINER_STYLE_MAP = {
    // ref: wni-common-styles/common/_notification.scss
    normal: 'gw-issue-normal',
    fine: 'gw-issue-fine',
    info: 'gw-issue-info',
    warning: 'gw-issue-warning',
    error: 'gw-issue-error',
};

const ISSUE_ICON_MAP = {
    normal: 'gw-error',
    fine: 'gw-info',
    info: 'gw-info',
    warning: DEFAULT_TYPE,
    error: 'gw-error',
};

function WniNotification(props) {
    const { type = DEFAULT_TYPE, icon = null, children } = props;

    return (
        <div className="notification-message-wrapper wniNotification">
            <Flex
                justifyContent='left'
                gap='none'
                className={ISSUES_CONTAINER_STYLE_MAP[type]}
            >
                <div  className="message-icon"><Icon icon={icon || ISSUE_ICON_MAP[type]} /></div>
                <div className="message-info">{children}</div>
            </Flex>
        </div>
    );
}

export default WniNotification;
