

import React, { useEffect } from 'react';
import { ApplicationHeader } from '@jutro/legacy/router';
import { CEConfigUtil } from 'wnice-portals-util-js';

function CustomHeader(props) {
  useEffect(()=>{
    /**
     * This is only for test setting
     * CEI-47 Go to myAccount system only when this user has legacy account
     */
    // eslint-disable-next-line no-secrets/no-secrets
    const logoEle = document.getElementsByClassName('jut__ApplicationHeader__applicationLogoTitleLink')[0]
    logoEle.addEventListener('click', (event) => {
      event.preventDefault(); // Prevent the default action of clicking the link
      window.location.href = CEConfigUtil.getMyAccountUrl()
    });
  },[])

  return (
    <ApplicationHeader
      {...props.header}
    />
  );
}

export default CustomHeader;


