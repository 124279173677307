import { MetadataContent } from '@jutro/legacy/uiconfig';
import _ from 'lodash';
import React, { useState } from 'react';
import metadata from './WniBaseModal.metadata.json5';



function WniBaseModal(props) {
    const { title, renderTitle, component, size = 'md', id, onClose, updateShowBaseModal, updateModalResult } = props;


    const renderComponent = (FieldComponent) => {
        if(!FieldComponent) {
            return null;
        }
        const componentProps = _.get(component, 'props', {});
        return <FieldComponent {...componentProps} onResolve={updateModalResult} onReject={() => updateShowBaseModal(false)}  />
    }

    const overrides = {
        baseModal: {
            className: `${size} wni__ModalNext__modal`,
        },
        baseModalHeader: {
            visible: title || onClose,
            ...props,
        },
        baseModalHeaderTitle: {
            content: renderTitle ? renderTitle() : title
        },
        baseModalBody: {
            content: renderComponent(component?.type),
        },
        baseModalFooter: {
            visible: false
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            onClose: () => updateShowBaseModal(false)
        },
        resolveComponentMap: {
        },
    };

    return (
        <MetadataContent
            uiProps={metadata.componentContent}
            overrideProps={overrides}
            {...resolvers}
        />
    );
}

export default WniBaseModal;
