import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
import { Button } from '@jutro/components';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import metadata from './GLInjuryDetail.metadata.json5';
import messages from '../../FNOLGLIncidentDetailsPage.messages';
import { createNewPersonWithoutPublicID } from '../../utils/NewContactUtil';

const AddNewPerson = 'AddNewPerson'
function GLInjuryDetail(props) {
    const {
        injuryVM,
        relatedPersonContacts,
        onDetailCancel,
        onDetailSave,
        syncWizardData,
        onValueChange
    } = props;

    const translator = useTranslator();
    const [showErrors, setShowErrors] = useState(false);
    const { isComponentValid, onValidate } = useValidation('GLInjuryDetail');
    
    const injuredContactPublicID = _.get(injuryVM.value, 'injuredContactPublicID');

    const injuredPersonSelectorAvailableValues = relatedPersonContacts.map((contact) => {
        return {
            code: _.get(contact, 'publicID'),
            name: contact.displayName
        }
    }).concat([{code: AddNewPerson, name: translator(messages.addPerson)}])

    const onInjuredSelectorChange = (value) => {
        const newInjuryIncidentVM = _.clone(injuryVM);
        
        let newInjury = null;
        if (value === AddNewPerson) {
            _.set(newInjuryIncidentVM.value, 'injuredContactPublicID', AddNewPerson);
            newInjury = {
                publicID: AddNewPerson,
                ...createNewPersonWithoutPublicID()
            };
        } else {
            _.set(newInjuryIncidentVM.value, 'injuredContactPublicID', value);
            newInjury = relatedPersonContacts.find((contact) => contact.publicID === value);
        }
        _.set(newInjuryIncidentVM.value, 'injuredContact', newInjury);
        syncWizardData(newInjuryIncidentVM);
    };

    const onSaveClicked = () => {
        if (!isComponentValid) {
            setShowErrors(true);
            return;
        }
        onDetailSave();
    };

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showOptional: true,
        },
        injuryPerson: {
            availableValues: injuredPersonSelectorAvailableValues,
            value: injuredContactPublicID,
            onValueChange: onInjuredSelectorChange
        },
        injuryPersonContainer: {
            visible: !!injuredContactPublicID
        }
    };

    const readValue = (fieldId, fieldPath) => {
        return readViewModelValue(
            metadata.componentContent, injuryVM, fieldId, fieldPath, overrideProps
        );
    };

    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {
        }
    };

    return (
        <>
            <ViewModelForm
                uiProps={metadata.componentContent}
                model={injuryVM}
                overrideProps={overrideProps}
                onValidationChange={onValidate}
                onValueChange={onValueChange}
                resolveValue={readValue}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                showErrors={showErrors}
            />
                    <div className='d-flex flex-direction-row-reverse'>
                        <Button
                            variant="primary" 
                            label={translator(messages.saveAndContinue)}
                            onClick={onSaveClicked}
                        />
                        <Button
                            className="mr-15 wni-button-link"
                            variant="secondary" 
                            label={translator(messages.cancel)}
                            onClick={onDetailCancel}
                        />
                    </div>
        </>
    );
}

GLInjuryDetail.propTypes = {
};
GLInjuryDetail.defaultProps = {
};
export default GLInjuryDetail;
