import { MetadataContent } from '@jutro/legacy/uiconfig';
import _ from 'lodash';
import React from 'react';
import styles from './CEBannerMessageComponent.module.scss';
import metadata from './CEBannerMessageComponent.metadata.json5';

const BANNER_MESSAGE_NAME = 'configuration.portal.accountmanagement.banner.message';

const CEBannerMessageComponent = (props) => {
    const runtimeProperties = _.get(props, 'businessData.runtimeProperties', []);

    const bannerMessage = _.find(
        runtimeProperties,
        (property) => _.get(property, 'name') === BANNER_MESSAGE_NAME
    );

    const render = () => {
        const overrides = {
            bannerMessageContainerSection: {
                visible: !_.isEmpty(_.get(bannerMessage, 'value')),
                className: styles.bannerMessageContainerSection
            },
            bannerMessageSection: {
                message: _.get(bannerMessage, 'value'),
                className: styles.bannerMessageSection
            },
        };
        const resolvers = {
            resolveClassNameMap: styles,
            resolveCallbackMap: {},
            resolveComponentMap: {},
        };

        return (
            <MetadataContent
                uiProps={metadata.componentContent}
                overrideProps={overrides}
                {...resolvers}
            />
        );
    };
    return render();
};

CEBannerMessageComponent.propTypes = {};

CEBannerMessageComponent.defaultProps = {};

export default CEBannerMessageComponent;
