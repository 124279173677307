import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';

import FNOLCRWizard from './FNOLCRWizard';
import FNOLCRLossTypePage from './pages/LossType/FNOLCRLossTypePage';
// import FNOLCRBusinessLocationPage from './pages/BusinessLocation/FNOLCRBusinessLocationPage';
import FNOLCRIncidentDetailsPage from './pages/IncidentDetails/FNOLCRIncidentDetailsPage';

setComponentMapOverrides(
    {
        FNOLCRWizard: { component: 'FNOLCRWizard' },
        FNOLCRLossTypePage: { component: 'FNOLCRLossTypePage' },
        // FNOLCRBusinessLocationPage: { component: 'FNOLCRBusinessLocationPage' },
        FNOLCRIncidentDetailsPage: { component: 'FNOLCRIncidentDetailsPage' }
    },
    {
        FNOLCRWizard,
        FNOLCRLossTypePage,
        // FNOLCRBusinessLocationPage,
        FNOLCRIncidentDetailsPage
    }
);

export {
    FNOLCRWizard as default,
    FNOLCRLossTypePage,
    // FNOLCRBusinessLocationPage,
    FNOLCRIncidentDetailsPage
};
