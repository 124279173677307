import _ from 'lodash';

import { InternalRouteConstants } from 'wni-portals-config-js';
import DebugDataUtil from '../DebugDataUtil';

const { URL_PREFIX_SKIP_WIZARD_LEAVE_PROMPT } = InternalRouteConstants;


/**
 * Returns a funtion that when it is invoked, the wizard will jump back
 * to the specific page.
 *
 * See ErrorsAndWarningsUtil.getValidationIssueJumpFnMap();
 * @param {array} wizardSteps [{id}], e.g. the steps defined in pa-wizard-config.json5
 * @param {Function} wizardJumpTo the wizard jump function provided by useWizardSteps()
 * @param {String} pageId page ID defined in pa-wizard-config.json5
 * @returns {Function} a function that will try to jump back to specific page,
 *                      or _.noop if page not found;
 */
function getJumpToPageFn(wizardSteps, wizardJumpTo, pageId) {
    const pageIndex = wizardSteps.findIndex((step) => step.id === pageId);
    if (pageIndex < 0) {
        return _.noop;
    }
    return () => {
        wizardJumpTo(pageIndex);
    };
}

// ====================================

function goToAccountSummaryPage(history, accountNumber) {
    history.push(`/internal/accounts/${accountNumber}/summary`)
};


function goToAccountPaymentPage(history, quoteID) {
    history.push(`/internal/account-quotes-payment/${quoteID}`);
}

function goToNewQuoteWizard(history, {
    productCode, postalCode, jobID
}) {
    history.push(`/internal/quote-wizard/${productCode}/${postalCode}/${jobID}`);
}


/**
 * @deprecated
 * @param {object} history 
 * @param {object} param1 
 */
function goToNewQuoteWizardAndSwitchToBranch(history, {
    productCode, postalCode, jobID, periodPublicID
}) {
    history.push(`/internal/quote-wizard/${productCode}/${postalCode}/${jobID}/${periodPublicID}`);
}


// ===================================
function goToPolicyChangeWizard(history, {
    productCode, policyNumber, jobID, landingPageID,
}) {
    history.push(`/internal/endorsement-wizard/${productCode}/${policyNumber}/${jobID}/${landingPageID}`);
}


// ===================================

function goToQuoteDetailsSummaryPage(history, {
    jobID,
}) {
    history.push(`/quotes/${jobID}/summary`);
}

function goToPolicyDetailsSummaryPage(history, {
    policyNumber,
}) {
    history.push(`/policies/${policyNumber}/summary`);
}

function goToChangeDetailsSummaryPage(history, {
    jobID,
}) {
    history.push(`/change/${jobID}/summary`)
}


function goToPoliciesPage(history) {
    history.push('/policies');
}



// ==================================================================


function skipWizardPromptAndGoToQuoteDetailsSummaryPage(history, {
    jobID,
}) {
    history.push(`${URL_PREFIX_SKIP_WIZARD_LEAVE_PROMPT}/quotes/${jobID}/summary`);
}


// ==================================================================
function getNewQouteWizardJumpLink({
    productCode, postalCode, jobID
}) {
    return `/internal/quote-wizard/${productCode}/${postalCode}/${jobID}`;
}

// ==================================================================
function isHeadingToAccountQuotesPage(toPath) {
    if (!toPath) {
        return false;
    }
    // To be refactored
    // Coveres both '/internal/account-quotes' and '/internal/account-quotes-payment'
    // /internal/accounts/ will take user to account summary page
    return toPath.startsWith('/internal/account-quotes') || toPath.startsWith('/internal/accounts/');
}


function isSkippingWizardLeavePromptURL(toPath) {
    return toPath.startsWith(URL_PREFIX_SKIP_WIZARD_LEAVE_PROMPT);
}

export default {
    getJumpToPageFn,
    //
    goToAccountSummaryPage,
    goToAccountPaymentPage,
    //
    goToNewQuoteWizard,
    // goToNewQuoteWizardAndSwitchToBranch,
    //
    goToPolicyChangeWizard,
    //
    goToQuoteDetailsSummaryPage,
    goToPolicyDetailsSummaryPage,
    goToChangeDetailsSummaryPage,
    goToPoliciesPage,
    //
    skipWizardPromptAndGoToQuoteDetailsSummaryPage,
    //
    getNewQouteWizardJumpLink,
    //
    isHeadingToAccountQuotesPage,
    isSkippingWizardLeavePromptURL,
    // goToAccountSummaryPageByCommonUIType
};
