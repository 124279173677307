import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';

export default class WniUsersProfileDetailsService {

    static hasPaperDeliveryMethod(authHeader = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('wniUserProfileInfo'), 'hasPaperDeliveryMethod', [], authHeader);
    }

    static getAccountsDeliveryMethods(authHeader = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('wniUserProfileInfo'), 'getAccountsDeliveryMethods', [], authHeader);
    }

    static updateAccountDeliveryMethod(accountDeliveryMethodDTO, authHeader = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('wniUserProfileInfo'), 'updateAccountDeliveryMethod', [accountDeliveryMethodDTO], authHeader);
    }

    static verifyEmailVerificationCode(accountDeliveryMethodDTO, authHeader = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('wniUserProfileInfo'), 'verifyEmailVerificationCode', [accountDeliveryMethodDTO], authHeader);
    }

    static refreshEmailVerificationCode(accountNumber, authHeader = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('wniUserProfileInfo'), 'refreshEmailVerificationCode', [accountNumber], authHeader);
    }

    static getAccountsPAYGDetails(authHeader = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('wniUserProfileInfo'), 'getAccountsPAYGDetails', [], authHeader);
    }

    static updatePAYGEmails(accountNumber, paygContacts, authHeader = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('wniUserProfileInfo'), 'updatePAYGEmails', [accountNumber, paygContacts], authHeader);
    }

    static toBusinessDataDTO(authHeader = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('wniUserProfileInfo'), 'toBusinessDataDTO', [], authHeader);
    }

    /**
     * Retrieves an accounts contact summary.
     *
     * @returns {Promise} the promise from the backend call
     */
    static updatePolicyholderPortalFlag(authHeader = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('wniUserProfileInfo'), 'updatePolicyholderPortalFlag', [], authHeader);
    }
}
