import React, { useCallback, useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
    Loader, Icon, FormattedDate, useModal
} from '@jutro/components';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useTranslator, IntlContext } from '@jutro/locale';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { WizardPageTemplate } from 'gw-portals-wizard-components-ui';
import { withRouter } from 'react-router-dom';
import { LobIconUtil } from '@xengage/gw-portals-util-js';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import moment from 'moment';
import { WniDateUtil } from 'wni-portals-util-js';
import { ProductUtil } from 'wnice-portals-util-react';
import { getNormalizedLOBName } from 'gw-portals-config-js';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { PolicyTypeWithPCData } from 'gw-capability-claim-react'

import Claim from '../../models/Claim';
import styles from './FNOLSelectPolicy.module.scss';
import metadata from './FNOLSelectPolicy.metadata.json5';
import messages from '../../FNOL.messages';

function CEFNOLSelectPolicy(props) {
    const modalApi = useModal();
    const {
        wizardData: claimVM,
        updateWizardData,
        history,
        basePath,
        steps,
        goNext,
        history: {
            location: { state }
        }
    } = props;
    const {
        accountNumber: accountNumberFromPrevPage,
        policyNumber: policyNumberFromPrevPage,
        redirectPath,
        claimNumber,
        claimStatus
    } = state || {};
    const { FNOLService } = useDependencies('FNOLService');
    const { ClaimService } = useDependencies('ClaimService');
    const { AccountService } = useDependencies('AccountService');
    const { PolicyService } = useDependencies('PolicyService');

    const translator = useTranslator();
    const intl = useContext(IntlContext);
    const [policyData, setPolicyData] = useState({});
    const [policyNumber, setPolicyNumber] = useState(policyNumberFromPrevPage);
    const [accountNumber, setAccountNumber] = useState(accountNumberFromPrevPage);
    const [isLoading, setIsLoading] = useState(true);
    const [isShowInfoMessage, setInfoMessage] = useState(false);
    const { authHeader } = useAuthentication();
    const { isComponentValid, registerComponentValidation } = useValidation('FNOLSelectPolicy');
    const [policySummariesFromApi, setPolicySummariesFromApi] = useState([]);

    const getFormattedDate = useCallback((items, index, property) => {
        if (typeof items[property.id] === 'undefined') {
            return '';
        }
        const dateFormatter = WniDateUtil.formatDateWithPattern(items[property.id], "MM/DD/YYYY");
        return <span>{dateFormatter}</span>;
    }, []);

    const processResponseData = useCallback((accountDetails) => {
        const policyDetails = [];
        if (appConfig.persona === 'policyholder') {
            accountDetails.forEach((policyInfo) => {
                const policy = {
                    product: policyInfo.policyNumber,
                    policyNumber: policyInfo.policyNumber,
                    productType: policyInfo.policyType,
                    productCode: policyInfo.productCode_Ext,
                    effectiveDate: policyInfo.effectiveDate,
                    effectiveDate_Ext: policyInfo.effectiveDate_Ext,
                    expirationDate: policyInfo.expirationDate_Ext
                };
                policyDetails.push(policy);
            });
        } else {
            const lobindex = 0;
            const allowedPolicy = appConfig.allowedPolicyTypes;
            accountDetails.policySummaries.forEach((policyInfo) => {
                const { isCancelled } = policyInfo;
                const businessLines = policyInfo.policyLines;
                const { productName } = policyInfo.product;
                const businessLine = businessLines[lobindex].lineOfBusinessCode;
                if (
                    productName !== 'Commercial Package'
                    && _.includes(allowedPolicy, businessLine)
                    && !isCancelled
                    && !moment().isBefore(moment(policyInfo.effective))
                ) {
                    const policy = {
                        product: policyInfo.policyNumber,
                        policyNumber: policyInfo.policyNumber,
                        productType: businessLine,
                        productCode: policyInfo.productCode_Ext,
                        effectiveDate: policyInfo.effective,
                        expirationDate: policyInfo.expiration
                    };
                    policyDetails.push(policy);
                }
            });
        }
        return policyDetails;
    }, []);

    const checkPageSkipped = useCallback(() => {
        const stepsWithoutSelectPolicy = steps.slice(1, steps.length);
        return basePath !== '/fnol-select-policy'
        && !stepsWithoutSelectPolicy.some((step) => step.visited);
    }, [basePath, steps]);

    const getUniquePolicies = useCallback((response) => {
        if (_.isEmpty(response)) {
            return [];
        }
        const searchedPolicies = [];
        response.forEach((res) => {
            // for CPP policy, only need one policy item
            const existingPolicyNumbers = searchedPolicies.map((policy) => policy.policyNumber);
            if (!existingPolicyNumbers.includes(res.policyNumber)) {
                searchedPolicies.push(res);
            }
        });
        return searchedPolicies;
    }, []);

    const getPolicyDetails = useCallback(
        (selectedAccountNumber) => {
            if (!checkPageSkipped()) {
                if (appConfig.persona === 'policyholder') {
                    const lossDate = _.get(claimVM.value, 'lossDate') || new Date().toJSON();
                    const policySearchCriteria = {
                        lossDate
                    };
                    return FNOLService
                        .searchPolicies(policySearchCriteria, authHeader).then((response) => {
                            const policyResponse = getUniquePolicies(response);
                            setPolicyData(processResponseData(policyResponse));
                        });
                } 
                return AccountService.getAccountDetails(selectedAccountNumber, authHeader).then(
                    (response) => {
                        setPolicyData(processResponseData(response));
                    }
                );
                
            }
        },
        [checkPageSkipped, AccountService, authHeader, claimVM.value, FNOLService, getUniquePolicies, processResponseData]
    );

    const redirectDraftClaimFlow = useCallback(() => {
        setIsLoading(true);
        if (_.isFunction(ClaimService.addRecentlyViewedClaim)) {
            ClaimService.addRecentlyViewedClaim(claimNumber, authHeader);
        }
        return FNOLService.getClaim(claimNumber, authHeader).then((getClaimResponse) => {
            setPolicyNumber(getClaimResponse.policy.policyNumber);
            claimVM.value = getClaimResponse;
            const normalizedLobCode = getNormalizedLOBName(claimVM.value.policy.policyType);
            return history.push({
                pathname: `/fnol-${normalizedLobCode}`,
                state: {
                    claimData: getClaimResponse,
                    redirectPath: redirectPath,
                    claimStatus: claimStatus
                }
            });
        });
    }, [ClaimService, FNOLService, claimNumber, authHeader, claimVM, history, redirectPath, claimStatus]);

    const getClaimData = useCallback(async () => {
        if(claimVM.value.claimNumber){
            setIsLoading(true);
            await FNOLService.getClaim(claimVM.value.claimNumber, authHeader).then((getClaimResponse) => {
                setPolicyNumber(getClaimResponse.policy.policyNumber);
                claimVM.value = getClaimResponse;
                updateWizardData(claimVM);
            });
            setIsLoading(false);
        }
        goNext();

    }, [FNOLService, authHeader, claimVM, goNext]);

    useEffect((() => {
        if (!_.isEmpty(claimNumber)) {
            redirectDraftClaimFlow();
        }
        if (checkPageSkipped()) {
            getClaimData()
        }
        /**
         * Give a default policy effective date then loss date won't show error message immediate
         */
        if(claimVM.value.policy === undefined){
            claimVM.value.policy = {
                effectiveDate: "1971-01-01T00:00:00Z"
            }
        }
        
        // This will call only once.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), []);

    useEffect(() => {
        const previousAccountNumber = _.get(claimVM.value, 'policy.accountNumber');
        let policySummaryPromise;
        let getPolicyDetailsPromise = new Promise(_.noop)
        const policyNumberVar = _.isNil(policyNumber)
            ? _.get(claimVM.value, 'policy.policyNumber')
            : policyNumber;
        setPolicyNumber(policyNumberVar);
        setAccountNumber(accountNumberFromPrevPage || previousAccountNumber);
        const flowCheck = redirectPath.split('/');
        if (
            (flowCheck[1] === 'accounts'
                    && claimStatus
                    !== translator({ id: 'typekey.ClaimState.draft', defaultMessage: 'draft' })
                    && (!_.isEmpty(accountNumberFromPrevPage) || !_.isEmpty(previousAccountNumber)))
                || appConfig.persona === 'policyholder'
        ) {
            getPolicyDetailsPromise = getPolicyDetails(accountNumberFromPrevPage || previousAccountNumber);
        }
        if (policySummariesFromApi.length === 0) {
            policySummaryPromise = PolicyService.getAccountPolicySummaries(authHeader).then(
                setPolicySummariesFromApi
            );
        } else {
            policySummaryPromise = Promise.resolve(policySummariesFromApi);
        }
        Promise.all([getPolicyDetailsPromise, policySummaryPromise])
            .finally(() => {
                setIsLoading(false);
            });
        
        // This will call only once.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const changePolicy = useCallback(() => {
        const previousLossDate = _.get(claimVM.value, 'lossDate');
        claimVM.value = new Claim();
        _.set(claimVM.value, 'lossDate', previousLossDate);
        updateWizardData(claimVM);
    }, [claimVM, updateWizardData]);

    const callSearchPolicyService = useCallback(() => {
        const policySearchCriteria = {
            policyNumber: policyNumber,
            lossDate: _.get(claimVM.value, 'lossDate')
        };
        return FNOLService.searchPolicies(policySearchCriteria, authHeader).then(
            (policyResponse) => {
                if (_.isEmpty(policyResponse) && basePath === '/fnol-select-policy') {
                    setInfoMessage(true);
                    return claimVM;
                }
                if (_.isEmpty(policyResponse)) {
                    setInfoMessage(true);
                    return false;
                }
                const policyType = _.get(claimVM.value, 'policy.productType');
                const isCPPPolicy = policyType === ProductUtil.CPP_PRODUCT_CODE;
                if (isCPPPolicy) {
                    const initCPPPolicy = {
                        policyNumber: policyNumber,
                        accountNumber: accountNumber,
                        policyType: ProductUtil.CPP_PRODUCT_CODE
                    };
                    _.set(claimVM.value, 'policy', initCPPPolicy);
                    _.set(claimVM.value, 'policies', policyResponse);
                } else {
                    _.set(claimVM.value, 'policy', policyResponse[0]);
                    _.set(claimVM.value, 'policy.accountNumber', accountNumber);
                }
                const normalizedLobCode = getNormalizedLOBName(claimVM.value.policy.policyType);
                const fnolLOBPath = `/fnol-${normalizedLobCode}`;
                // if the URL contains the lob code, we have already navigated from the first step
                // and do not need to force the redirect to `/fnol-${normalizedLobCode}`
                if(history.location.pathname.includes(fnolLOBPath)) {
                    return claimVM;
                }

                return history.push({
                    pathname: fnolLOBPath,
                    state: {
                        claimData: claimVM.value,
                        redirectPath: redirectPath
                    }
                });
            }
        );
    }, [
        policyNumber,
        claimVM,
        FNOLService,
        authHeader,
        basePath,
        accountNumber,
        history,
        redirectPath
    ]);

    const onNext = useCallback(() => {
        const prevPolicyNo = _.get(claimVM.value, 'policy.policyNumber');
        if (!_.isEmpty(prevPolicyNo) && !_.isEmpty(policyNumber) && prevPolicyNo !== policyNumber) {
            return modalApi.showConfirm({
                title: messages.policyChangeModalTitle,
                message: messages.policyChangeModalMessage,
                status: 'warning',
                icon: 'gw-error-outline',
                confirmButtonText: messages.changePolicyButtonlabel,
                cancelButtonText: messages.fnolMessagePopupCancel
            }).then((results) => {
                if (results === 'cancel' || results === 'close') {
                    return _.noop();
                }
                changePolicy();
                return callSearchPolicyService();
            }, _.noop);
        }
        return callSearchPolicyService();
    }, [callSearchPolicyService, changePolicy, claimVM.value, modalApi, policyNumber]);

    const onPolicySelect = useCallback(
        (selectedValue)=> {
            if (_.get(claimVM.value, 'policy.accountNumber')) {
                setAccountNumber(_.get(claimVM.value, 'policy.accountNumber'));
            }
            const policyRowData = policyData.find((policy) => policy.policyNumber === selectedValue)
            _.set(claimVM.value, 'selectedPolicy', selectedValue);
            _.set(claimVM.value, 'policy', policyRowData);
            updateWizardData(claimVM);
            setPolicyNumber(selectedValue);
        },
        [claimVM, policyData, updateWizardData]
    );

    const getCell = useCallback(
        (item, index, property) => {
            return <span>{item[property.id]}</span>;
        },
        []
    );

    const generateDataForRadioButton = useCallback((row) => {
        return {
            code: row.policyNumber,
            value: ''
        };
    }, []);

    const getProductCode = useCallback((item) => {
        return (
            <PolicyTypeWithPCData showIMTooltip policySummariesFromApi={policySummariesFromApi} rowData={item} />
        )
    }, [policySummariesFromApi]);

    const dateIsInFuture = useCallback((date) => {
        if (!date) {
            return false;
        }

        const currentTime = new Date();
        const selectedDate = new Date(date);
        return selectedDate > currentTime;
    }, []);

    const isPageValid = useCallback(() => {
        if (_.isEmpty(policyNumber) && _.isEmpty(_.get(claimVM.value, 'policy.policyNumber'))) {
            return false;
        }
        if (!_.get(claimVM.value, 'lossDate') || dateIsInFuture(_.get(claimVM.value, 'lossDate'))) {
            return false;
        }
        if (_.get(claimVM.value, 'lossDate') < _.get(claimVM.value, 'policy.effectiveDate')) {
            return false;
        }
        return true;
    }, [claimVM.value, dateIsInFuture, policyNumber]);

    useEffect(() => {
        registerComponentValidation(isPageValid);
    }, [isPageValid, registerComponentValidation]);

    const getPolicyNumberHeading = useCallback(() => {
        const previousPolicyNumber = _.get(claimVM.value, 'policy.policyNumber');
        const policyNumberToDisplay = policyNumber || previousPolicyNumber || '';
        return translator(messages.lossInformation, { policyNumber: policyNumberToDisplay });
    }, [claimVM.value, translator, policyNumber]);

    const overrides = {
        '@field': {
            disabled: !_.isNil(_.get(claimVM, 'value.claimNumber'))
        },
        policyTable: {
            data: policyData
        },
        policyTableHeadingId: {
            visible: policyData.length > 0
        },
        policyLossDateHeadingId: {
            content: getPolicyNumberHeading()
        },
        policySelectRadio: {
            disabled: !_.isNil(_.get(claimVM, 'value.claimNumber')),
            option: generateDataForRadioButton,
            value:
                policyNumber
                || (!_.isEmpty(claimVM.value) && _.get(claimVM.value, 'policy.policyNumber')),
            onValueChange: onPolicySelect
        },
        noPolicyInfoLableId: {
            visible: isShowInfoMessage
        },
        lossDateId: {
            // defaultValue: new Date(),
            phoneWideTime: {
                labelPosition: 'top'
            },
        }
    };
    const resolvers = {
        resolveCallbackMap: {
            getCell: getCell,
            getFormattedDate: getFormattedDate,
            getProductCode: getProductCode
        },
        resolveClassNameMap: styles
    };
    if (isLoading) {
        return <Loader loaded={!isLoading} />;
    }
    return (
        <WizardPage
            onNext={onNext}
            showPrevious={false}
            disableNext={!isComponentValid}
            template={WizardPageTemplate}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={claimVM}
                overrideProps={overrides}
                onModelChange={updateWizardData}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
            />
        </WizardPage>
    );
}

CEFNOLSelectPolicy.propTypes = wizardProps;
CEFNOLSelectPolicy.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired
};

export default withRouter(CEFNOLSelectPolicy);
