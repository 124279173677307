import React, { useCallback, useContext } from 'react';
import _ from 'lodash';
import { WniCurrency } from 'wni-common-base-components';
import { CurrencyValue } from '@jutro/components';
import PropTypes from 'prop-types';
import { IntlContext } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { hasUSLHItem } from '../../utils/WCPremiumReportUtil';
import metadata from './RatingPeriodComponent.metadata.json5';
import styles from './RatingPeriodComponent.module.scss';


function RatingPeriodComponent(props) {
    const intl = useContext(IntlContext);
    const {
        data: ratingPeriodVM,
        wizardData,
        updateWizardData,
        ratingPeriodPath
    } = props;

    const { 
        jobStatus
    } = wizardData.value;

    const readOnly = jobStatus === 'AuditComplete' || jobStatus === 'Quoted';
    const hasUSLHData = hasUSLHItem(ratingPeriodVM.value);

    const getFormattedDate = useCallback((date) => {
        const newDate = new Date(date.year, date.month, date.day);
        return intl.formatDate(newDate, { year: 'numeric', month: 'short', day: 'numeric' })
    }, [intl]);

    const getRatingPeriodTitle = useCallback(() => {
        const periodStart = getFormattedDate(ratingPeriodVM.periodStart.value);
        const periodEnd = getFormattedDate(ratingPeriodVM.periodEnd.value);
        return `${periodStart} - ${periodEnd}`;
    }, [getFormattedDate, ratingPeriodVM]);

    const renderCell = (item, index, property) => {
        if (property.id === 'actual' || property.id === 'actualUSLH') {
            const currencyValue = {
                amount: _.get(item, `value.${property.id}`),
                currency: 'USD'
            }
            return (
                <CurrencyValue
                    id={`${property.id}_${index}_${_.get(item, 'value.covEmpPublicID')}`}
                    value={currencyValue}
                    showFractions={false}
                />
            );
        }
        return _.get(item, `value.${property.id}`);
     };

     const writeValue = useCallback((newCurrencyValue, selectedColumnPath) => {
        _.set(wizardData, `${selectedColumnPath}.value`, newCurrencyValue.amount);
        updateWizardData(wizardData);
     }, [updateWizardData, wizardData]);

     const renderActualCell = (item, index, property) => {
        const selectedBasePath = `${ratingPeriodPath}.detailItems.children[${index}]`;
        const selectedActualPath = `${selectedBasePath}.${property.id}`;
        if (readOnly) {
            return renderCell(item, index, property); 
        }
        const canInput = property.id === 'actual' || (property.id === 'actualUSLH' && item.value.hasUSLH);
        if (canInput) {
            const currencyValue = {
                amount: _.get(item, `value.${property.id}`),
                currency: 'USD'
            }
            return (
                <WniCurrency
                    id={`${property.id}_${index}_${_.get(item, 'value.covEmpPublicID')}`}
                    path={selectedActualPath}
                    onValueChange={(newCurrencyValue) => writeValue(newCurrencyValue, selectedActualPath)}
                    value={currencyValue}
                    required
                    showFractions={false}
                    hideLabel
                />
            );
        }
        return null;
    }

    const overrides = {
        ratingPeriodTitle: {
            content: getRatingPeriodTitle()
        },
        detailItemsTable: {
            visible: !hasUSLHData,
            data: ratingPeriodVM.detailItems.children
        },
        detailItemsTableWithUSLH: {
            visible: hasUSLHData,
            data: ratingPeriodVM.detailItems.children
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            renderCell,
            renderActualCell
        }     
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={ratingPeriodVM}
            overrideProps={overrides}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );
}

RatingPeriodComponent.propTypes = {
    data: PropTypes.array.isRequired,
    wizardData: PropTypes.object.isRequired,
    updateWizardData: PropTypes.func.isRequired,
    ratingPeriodPath: PropTypes.string.isRequired
};

export default RatingPeriodComponent;
