import _ from 'lodash';
import { useAuthentication } from '@xengage/gw-digital-auth-react';

function useBusinessData() {
    const {
        authUserData: {
            businessData_Ext: {
                featureToggles = [],
                enableCommercialLine,
                systemDate,
                runtimeProperties
            } = {}
        } = {}
    } = useAuthentication();


    const getFeatureCodeValue = (featureCode) => {
        if (!featureCode) {
            return false;
        }
        const findItem = featureToggles.find((item) => item.code === featureCode)
        if(!findItem) {
            return false
        }
        return findItem.enabled;
    }


    return {
        getFeatureCodeValue,
        enableCommercialLine,
        systemDate,
        runtimeProperties
    };

}

export default useBusinessData;
