import React, { useCallback, useMemo } from 'react';
import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { UWCompanyUtil } from 'wnice-portals-util-js';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { Icon, Tooltip } from '@jutro/components';

import metadata from './AmountSelection.metadata.json5';
import styles from './AmountSelection.module.scss';
import messages from './AmountSelection.messages';

function AmountSelection(props) {
    const { 
        invoiceStreamDetails = {}, 
        policyOptions,
        amountList,
        formatAmountToCurrency,
        amountSelectionVars,
        updateAmountSelectionVars,
        updateManualAmount,
        updateShowAmountError,
        minToPay
    } = props;

    const translator = useTranslator();

    const { getDefaultDomain } = UWCompanyUtil;
    const domainCompany = getDefaultDomain();
    const { 
        hasPayAsYouGo_Ext: hasPayAsYouGo
    } = invoiceStreamDetails;

    const getFullPayAmountLabel = () => {
        const fullPayDiscount = _.get(invoiceStreamDetails, 'fullPayDiscount_Ext.amount')
        return (
            <div className={cx(styles.discountLabel)}>
                <span>
                    {translator(messages.fullPayAamount)}
                </span>
                <Tooltip
                    content={translator(messages.fullPayHelpText, {amount: fullPayDiscount})}
                    id="tooltip"
                    placement="top"
                    className={styles.labelWithTooltip}
                >
                    <Icon icon="gw-help" className={styles.tooltipIcon}/>
                </Tooltip>
                <br />
                <span className={cx(styles.discountSubTitle)}>
                    {translator(messages.fullPayDiscount, { amount: fullPayDiscount })}
                </span>
                
                
            </div>
        )
    }

    const onSelectAmount = useCallback((selectedCodes, minAmount) => {
        const checked = !_.isEmpty(selectedCodes);
        // when user chose Account Balance/Minimum Amount/Minimum Amount With Full Pay Discount checkbox
        updateAmountSelectionVars({
            ...amountSelectionVars,
            selectedPolicies: [],
            selectedCode: checked ? [selectedCodes[selectedCodes.length - 1]] : [],
            amountSentToIC: checked ? minAmount : null
        });
        updateManualAmount(null);
        updateShowAmountError(false);
    },[amountSelectionVars, updateAmountSelectionVars, updateManualAmount, updateShowAmountError])

    const onSelectPolicyOptions = useCallback((selectedItems, currentPolicy) => {
        const {
            minimumDue: {
                amount
            },
            policyNumber: currentPolicyNumber
        } = currentPolicy;
        const selectedPolicyNumber = selectedItems[0];
        const { selectedPolicies } = amountSelectionVars;
        const newAmountSelectionVars = {
            ...amountSelectionVars,
            selectedCode: []
        };
        if (!_.isEmpty(selectedItems)) {
            // when user checked the Policy Min Payment checkbox
            updateAmountSelectionVars({
                ...newAmountSelectionVars,
                selectedPolicies: [selectedPolicyNumber],
                amountSentToIC: amount
            });
        } else {
            const newPolicies = _.clone(selectedPolicies);
            const index = _.findIndex(newPolicies, (np) => np === currentPolicyNumber);
            newPolicies.splice(index, 1);
            updateAmountSelectionVars({
                ...newAmountSelectionVars,
                selectedPolicies: newPolicies,
                amountSentToIC: 0
            });
        }
        updateManualAmount(null);
        updateShowAmountError(false);
    },[amountSelectionVars, updateAmountSelectionVars, updateManualAmount, updateShowAmountError])

    const onSelectPAYGCheckbox = useCallback((selectedCodes, checkedAmount) => {
        const checked = !_.isEmpty(selectedCodes);
        updateAmountSelectionVars({
            ...amountSelectionVars,
            selectedPAYGCode: checked ? [selectedCodes[selectedCodes.length - 1]] : [],
            amountSentToIC: checked ? checkedAmount : null
        });
        // when choose the checkbox with other field has input, need to clear that data
        updateManualAmount(null);
        updateShowAmountError(false);
    },[amountSelectionVars, updateAmountSelectionVars, updateManualAmount, updateShowAmountError])
    
    const getLabelWithTooltip = useCallback(
        (policy) => {
            const pendingCacenlTooltip = () => {
                
                if(['pendingcancellation', 'canceled', 'open'].findIndex(v => v === policy.cancelStatus) !== -1){
                    if(policy.cancelStatus === 'canceled'){
                        return (
                            <Tooltip
                                content={translator(messages.cancelled)}
                                id="tooltip"
                                placement="top"
                                className={styles.pendingCancelTooltip}
                            >
                                <Icon className={cx(styles.tooltipIcon, styles.greyTooltipIcon)} icon="gw-block" />
                            </Tooltip>
                        )
                    }
                    if(policy.cancelStatus === 'pendingcancellation'){
                        return (
                            <Tooltip
                                content={translator(messages.pendingCancel)}
                                id="tooltip"
                                placement="top"
                                className={styles.pendingCancelTooltip}
                            >
                                <Icon className={cx(styles.tooltipIcon, styles.errorTooltipIcon)} icon="gw-error" />
                            </Tooltip>
                        )
                    }
                    // if policy expired
                    if(new Date() > new Date(policy.expirationDate)){
                        return (
                            <Tooltip
                                content={translator(messages.expired)}
                                id="tooltip"
                                placement="top"
                                className={styles.pendingCancelTooltip}
                            >
                                <Icon className={cx(styles.tooltipIcon, styles.greyTooltipIcon)} icon="gw-block" />
                            </Tooltip>
                        )
                    }
                    
                }
                return null
            }
            const amountLabel = `${policy.productCode} (${policy.policyNumber}) Minimum Payment `;
            return (
                <div className={styles.labelWithTooltip}>
                    {amountLabel}
                    {pendingCacenlTooltip()}
                </div>
            )
        },
        [translator]
    )

    const policyOptionsOverrides = useMemo(() => {
        const { selectedPolicies } = amountSelectionVars;
        const overrides = policyOptions.map((policy, index) => {
            return {
                [`policyOption${index}`]: {
                    value: selectedPolicies.includes(policy.policyNumber) ? [policy.policyNumber] : [],
                    availableValues: [
                        {
                          "code": policy.policyNumber,
                          "name": formatAmountToCurrency(policy.minimumDue.amount)
                        }
                    ],
                    visible: policy.minimumDue.amount > 0,
                    label: getLabelWithTooltip(policy),
                    onValueChange: (value) => onSelectPolicyOptions(value, policy)
                }
            };
        });

        return Object.assign({}, ...overrides);
    }, [amountSelectionVars, policyOptions, formatAmountToCurrency, getLabelWithTooltip, onSelectPolicyOptions]);

    const overrideProps = {
        ...policyOptionsOverrides,
        '@field': {
            labelPosition: 'left',
            phoneWide: {
                labelPosition: 'top'
            },
        },
        amountSelectionContainer: {
            visible: !hasPayAsYouGo
        },
        minimumAmountToPayId: {
            visible: !!amountList[0].amount && !invoiceStreamDetails.notGoodStatus_Ext,
            availableValues: [
                {
                  "code": "0",
                  "name": formatAmountToCurrency(amountList[0]?.amount)
                }
            ],
            onValueChange: (value) => onSelectAmount(value, amountList[0]?.amount),
            value: amountSelectionVars.selectedCode
        },
        fullPayAmountId: {
            label: getFullPayAmountLabel(),
            visible: !!amountList[1].amount && !invoiceStreamDetails.notGoodStatus_Ext,
            availableValues: [
                {
                  "code": "1",
                  "name": formatAmountToCurrency(amountList[1]?.amount)
                }
            ],
            onValueChange: (value) => onSelectAmount(value, amountList[1]?.amount),
            value: amountSelectionVars.selectedCode
        },
        invoiceBalanceToPayId: {
            availableValues: [
                {
                  "code": "2",
                  "name": formatAmountToCurrency(amountList[2]?.amount)
                }
            ],
            onValueChange: (value) => onSelectAmount(value, amountList[2]?.amount),
            value: amountSelectionVars.selectedCode,
            visible: !!amountList[2].amount,
            label: translator(messages.invoiceBalanceToPay)
        },
        policyOptions: {
            visible: policyOptions.length > 0,
            data: policyOptions
        },
        amountSelectionPAYGContainer: {
            visible: hasPayAsYouGo
        },
        policyPAYGOption: {
            visible: policyOptions.length > 0 && !!policyOptions[0].payAsYouGoMinimumDue?.amount,
            value: amountSelectionVars.selectedPAYGCode,
            availableValues: policyOptions.length > 0 ? [
                {
                    code: policyOptions[0]?.policyNumber,
                    name: formatAmountToCurrency(policyOptions[0]?.payAsYouGoMinimumDue?.amount)
                }
            ] : undefined,
            label: policyOptions.length > 0 ? getLabelWithTooltip(policyOptions[0]) : undefined,
            onValueChange: (value) => onSelectPAYGCheckbox(value, policyOptions[0]?.payAsYouGoMinimumDue?.amount)
        },
        currentDueTotal: {
            visible: policyOptions.length > 0 && !!policyOptions[0].currentDueTotal?.amount,
            value: amountSelectionVars.selectedPAYGCode,
            availableValues: policyOptions.length > 0 ? [
                {
                  code: 'currentDueTotal',
                  name: formatAmountToCurrency(policyOptions[0]?.currentDueTotal?.amount)
                }
            ] : undefined,
            label: translator(messages.currentDueToPay),
            onValueChange: (value) => onSelectPAYGCheckbox(value, policyOptions[0]?.currentDueTotal?.amount)
        },
        helpMessageContainer: {
            visible: minToPay > 50000
        },
        invoiceCloudContactMsg: {
            content: translator(messages.contactHelpMsg, {
                contactDetail: domainCompany.code === 'UIC' ? translator(messages.uicNumber) : translator(messages.wnNumber)
            })
        }
    }

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onSelectAmount,
        }
    };

    const data = {
        policyOptions: amountList.policyOptions
    };

    return (
        <ViewModelForm
            model={data}
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap} />
    );
}

AmountSelection.propTypes = {
    formatAmountToCurrency: PropTypes.func.isRequired,
    amountSelectionVars: PropTypes.object.isRequired,
    updateAmountSelectionVars: PropTypes.func.isRequired,
    updateManualAmount: PropTypes.func.isRequired,
    updateShowAmountError: PropTypes.func.isRequired
};

AmountSelection.defaultProps = {
    formatAmountToCurrency: _.noop,
    updateAmountSelectionVars: _.noop,
    updateManualAmount: _.noop,
    updateShowAmountError: _.noop
};

export default AmountSelection;

