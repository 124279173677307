import { defineMessages } from 'react-intl';

export default defineMessages({
    accountInformation: {
        id: 'usersprofile.views.amp-users-profile.Account Information',
        defaultMessage: 'Account Information'
    },
    issueSavingDetails: {
        id: 'usersprofile.views.amp-users-profile.Issue saving details, please try again',
        defaultMessage: 'Issue saving details, please try again'
    },
    accountDetails: {
        id: 'usersprofile.views.amp-users-profile.Account Details',
        defaultMessage: 'Account Details'
    },
    accountTitleWithInForcePolicies: {
        id: 'usersprofile.views.amp-users-profile.Account Details:',
        defaultMessage: 'Account Details: {accountName} ({accountNumber})'
    },
    accountTitleWithNoInForcePolicies: {
        id: 'usersprofile.views.amp-users-profile.Below are the details of the account that has no active policies',
        defaultMessage: 'Below are the details of the account that has no active policies'
    },
    billingDetails: {
        id: 'usersprofile.views.amp-users-profile.Billing Details',
        defaultMessage: 'Billing Details'
    },
    billingEqualAddress: {
        id: 'usersprofile.views.amp-users-profile.Is your Billing Address the same as the one above?',
        defaultMessage: 'Is your Billing Address the same as the one above?'
    },
    billingIsTheSame: {
        id: 'usersprofile.views.amp-users-profile.Your billing address is the same.',
        defaultMessage: 'Your billing address is the same.'
    },
    wantToEditInstruction: {
        id: 'usersprofile.views.amp-users-profile.If you want to edit any of Account Details, Please reach to your agency.',
        defaultMessage: 'If you want to edit any of Account Details, Please reach to your agency.'
    },
    billingDelivery: {
        id: 'usersprofile.views.amp-users-profile.Billing Delivery',
        defaultMessage: 'Billing Delivery'
    },
    deliveryMethod: {
        id: 'usersprofile.views.amp-users-profile.Delivery Method',
        defaultMessage: 'Delivery Method'
    },
    sentByMail: {
        id: 'usersprofile.views.amp-users-profile.Sent in the mail',
        defaultMessage: 'Sent in the mail'
    },
    sentByEmail: {
        id: 'usersprofile.views.amp-users-profile.Sent by email',
        defaultMessage: 'Sent by email'
    },
    applyToAllAccountsTitle: {
        id: 'usersprofile.views.amp-users-profile.Apply Contact Details to Other Policies',
        defaultMessage: 'Apply Contact Details to Other Policies'
    },
    applyToAllAccounts: {
        id: 'usersprofile.directives.templates.user-details-apply-to-all.Do you want to use these contact details for policies that belong to other accounts?',
        defaultMessage: 'Do you want to use these contact details for policies that belong to other accounts?'
    },
    applyToAllAccountsYes: {
        id: 'usersprofile.directives.templates.user-details-apply-to-all.Yes',
        defaultMessage: 'Yes'
    },
    applyToAllAccountsNo: {
        id: 'usersprofile.directives.templates.user-details-apply-to-all.No',
        defaultMessage: 'No'
    },
    cancel: {
        id: 'usersprofile.views.amp-users-profile.Cancel',
        defaultMessage: 'Cancel'
    },
    save: {
        id: 'usersprofile.views.amp-users-profile.Save',
        defaultMessage: 'Save'
    },
    cancelEditTitle: {
        id: 'usersprofile.views.amp-users-profile.Cancel contact information update',
        defaultMessage: 'Cancel contact information update'
    },
    cancelEditMessage: {
        id: 'usersprofile.views.amp-users-profile.Are you sure you want to cancel your account contact profile changes?',
        defaultMessage: 'Are you sure you want to cancel your account contact profile changes?'
    },
    doYouAgree: {
        id: 'usersprofile.views.amp-users-profile.Do you agree to the ',
        defaultMessage: 'Do you agree to the '
    },
    termsAndConditions: {
        id: 'usersprofile.views.amp-users-profile.Terms & Conditions',
        defaultMessage: 'Terms & Conditions'
    },
    clickTheLink: {
        id: 'usersprofile.views.amp-users-profile.Click the link to view and agree',
        defaultMessage: '(Click the link to view and agree.)'
    },
    thankyou: {
        id: 'usersprofile.views.amp-users-profile.Thank you!',
        defaultMessage: 'Thank you!'
    },
    weHaveSentAnEmail: {
        id: 'usersprofile.views.amp-users-profile.We have sent an email to the address provided',
        defaultMessage: 'We\'ve sent an email to the address provided. \nPlease check your inbox to verify your email and update your document delivery method.'
    },
    docDeliveryMethodUpdated: {
        id: 'usersprofile.views.amp-users-profile.Your document delivery method has been updated.',
        defaultMessage: 'Your document delivery method has been updated.'
    },
    confirmExit: {
        id: 'usersprofile.views.amp-users-profile.Are you sure you want to exit?',
        defaultMessage: 'Are you sure you want to exit?'
    },
    confirmExitMsg: {
        id: 'usersprofile.views.amp-users-profile.Your changes will not be saved.',
        defaultMessage: 'Your changes will not be saved.'
    },
    emailVerifiedLabel :{
         id: "usersprofile.views.amp-users-profile.Email Verified",
         defaultMessage: "Email Verified"
    },
    emailVerifiedTooltip:{
        id: "usersprofile.views..You must verify your email in order to sign up for Electronic/Portal Delivery",
        defaultMessage: "You must verify your email in order to sign up for Electronic/Portal Delivery"
    },
    infoYes: {
        id: 'usersprofile.views.amp-users-profile.Email Verified Yes',
        defaultMessage: 'Yes'
    },
    infoNo: {
        id: 'usersprofile.views.amp-users-profile.Email Verified No',
        defaultMessage: 'No'
    },
    termCondition1: {
        id: 'usersprofile.views.amp-users-profile.term-conditions.term condition 1',
        defaultMessage: '1. Use of this on-line service requires you to view documents and bills that may be available in accordance with the terms of your account.'
    },
    termCondition2: {
        id: 'usersprofile.views.amp-users-profile.term-conditions.term condition 2',
        defaultMessage: '2. Use of this service also allows you, at your option, to discontinue paper versions of your policy and/or bills. If you elect this option, the information that is typically provided via the USPS will be provided through the Western National Portal. By electing this option, you agree to receipt of your policy and/or bills on-line rather than by mail. All communications in either electronic or paper format from us to you will be considered \"in writing\". You should print or download a copy of these Terms and Conditions and any other electronic communication that is important to you.'
    },
    termCondition3: {
        id: 'usersprofile.views.amp-users-profile.term-conditions.term condition 3',
        defaultMessage: '3. This service is provided as a convenience to you, as our customer. Both you, and Western National Insurance Group have the right to discontinue the service and use other billing methods at any time. To discontinue the service at any time, you may do so by logging on to the Western National Portal and making this change through the Profile section.'
    },
    termCondition4: {
        id: 'usersprofile.views.amp-users-profile.term-conditions.term condition 4',
        defaultMessage: '4. To accept the above Terms and Conditions and enroll in Paperless/Electronic Delivery, please click the \"I agree\" button below. You acknowledge that by clicking the \"I agree\" button, you are indicating your intent to sign the relevant document or record and that this shall constitute your signature.'
    },
    termCondition5: {
        id: 'usersprofile.views.amp-users-profile.term-conditions.term condition 5',
        defaultMessage: '5. You acknowledge and agree that your consent to electronic communications is being provided in connection with a transaction affecting interstate commerce that is subject to the federal Electronic Signatures in Global and National Commerce Act, and that we both intend that the Act apply to the fullest extent possible to validate our ability to conduct business with you by electronic means.'
    },
    emailFor: {
        id: 'usersprofile.views.amp-users-profile.Email For',
        defaultMessage: 'Email For'
    },
    phoneWidePrintMailDelivery: {
        id: 'usersprofile.views.amp-users-profile.Print',
        defaultMessage: 'Print'
    },
    printMailDelivery: {
        id: 'usersprofile.views.amp-users-profile.Print/Mail Delivery',
        defaultMessage: 'Print/Mail Delivery'
    },
    phoneWideElectronicPortalDelivery: {
        id: 'usersprofile.views.amp-users-profile.Electronic',
        defaultMessage: 'Electronic'
    },
    electronicPortalDelivery: {
        id: 'usersprofile.views.amp-users-profile.Electronic/Portal Delivery',
        defaultMessage: 'Electronic/Portal Delivery'
    }
});
