/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {
    useEffect, useState, useCallback, useContext, useMemo
} from 'react';
import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
import { Loader, useModal, TooltipIcon } from '@jutro/components';
import {
    ViewModelServiceContext,
    withViewModelService,
    ViewModelForm
} from '@xengage/gw-portals-viewmodel-react';
import { withRouter } from 'react-router-dom';
import { Grid, BreakpointTrackerContext } from '@jutro/layout';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { UsersProfileDetailsService } from 'gw-capability-profileinfo';
import { WniUsersProfileDetailsService } from 'wnice-capability-profileinfo';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { ProductUtil } from 'wnice-portals-util-react';
import { TermConditionsPopup } from 'gw-components-platform-react';
import { Link } from '@jutro/router';

import EmailVerificationPopup from '../../components/EmailVerificationPopup/EmailVerificationPopup';
import CustomConfirmPopup from '../../components/CustomConfirmPopup/CustomConfirmPopup';
import UserDetailsComponent from '../../components/UserDetailsComponent/UserDetailsComponent';
import PAYGEmailsComponent from '../../components/PAYGEmailsComponent/PAYGEmailsComponent';
import metadata from './UsersProfile.metadata.json5';
import messages from './UsersProfile.messages';
import styles from './UsersProfile.module.scss';


const NOT_EDIT_MODE_INDEX = -1;

const hasPoliciesInForce = (accountInfo) => {
    return accountInfo.relatedPolicies.length > 0;
};

const deliveryMethodChangedInForm = (deliveryMethod, backupDeliveryMethod) => {
    return (deliveryMethod.policyHolderPrintPref !== backupDeliveryMethod.policyHolderPrintPref) ||
        (deliveryMethod.email !== backupDeliveryMethod.email) ||
        (deliveryMethod.email === backupDeliveryMethod.email && !backupDeliveryMethod.emailVerified);
};

const getPAYGEmailChangesInForm = (paygDetailsVM, backupPaygDetails) => {
    const filteredChanges = paygDetailsVM.filter((vm) => {
        const { contactVM } = vm;
        const backupPaygDetail = backupPaygDetails.find((bakDetail) => bakDetail.publicID === contactVM.publicID.value);
        return contactVM.emailAddress.value !== backupPaygDetail.emailAddress;
    });
    const paygChanges = filteredChanges.map((vm) => {
        const { contactVM } = vm;
        return {
            publicID: contactVM.publicID.value,
            emailAddress: contactVM.emailAddress.value
        };
    })
    return _.uniqBy(paygChanges, 'publicID');
};

function UsersProfile(props) {

    const modalApi = useModal();
    const translator = useTranslator();
    const viewModelService = useContext(ViewModelServiceContext);
    const { authHeader } = useAuthentication();
    const { onValidate } = useValidation(
        'UsersProfile'
    );
    const { match, history } = props;
    const accountNumberEdit = _.get(match, 'params.accountNumber');
    const [displayLoader, updateDisplayLoader] = useState(true);
    const [editModeState, updateEditModeState] = useState({
        accountInEditIndex: NOT_EDIT_MODE_INDEX,
        checkTermConditions: true
    });
    const [isMultiAccount, updateIsMultiAccount] = useState(false);
    const [accountsDetails, updateAccountsDetails] = useState({});
    const [backupDeliveryMethods, updateBackupDeliveryMethods] = useState([]);
    const [backupPAYGContacts, updateBackupPAYGContacts] = useState([]);
    const breakpoint = useContext(BreakpointTrackerContext);
    const isPhone = breakpoint === 'phoneWide' || breakpoint === 'phone';
    const updateAccounts = useCallback((model) => {
        const { accounts: updatedDetails } = model;
        updateAccountsDetails({ accounts: [...updatedDetails] });
    }, []);

    const resetBackupDeliveryMethod = useCallback((deliveryMethod, accountInEditIndex) => {
        _.set(backupDeliveryMethods, accountInEditIndex, deliveryMethod);
        updateBackupDeliveryMethods(_.cloneDeep(backupDeliveryMethods));
        const { accounts } = accountsDetails;
        accounts[accountInEditIndex].deliveryMethodVM.value = _.cloneDeep(deliveryMethod);
        updateAccounts({ accounts });
    }, [accountsDetails, backupDeliveryMethods, updateAccounts]);

    const renderTermConditionContent = useCallback(() => {
        return (
            <Grid>
                <div>{translator(messages.termCondition1)}</div>
                <div>{translator(messages.termCondition2)}</div>
                <div>{translator(messages.termCondition3)}</div>
                <div>{translator(messages.termCondition4)}</div>
                <div>{translator(messages.termCondition5)}</div>
            </Grid>
        );
    }, [translator]);

    const openTermsAndConditionsPopup = useCallback(() => {
        const componentProps = {
            title: translator(messages.termsAndConditions),
            renderTermConditionContent: renderTermConditionContent
        };
        modalApi.showModal(<TermConditionsPopup {...componentProps} />).then(() => {
            // when user clicked Agree button for the popup
            const { checkTermConditions } = editModeState;
            if (!checkTermConditions) {
                updateEditModeState({ ...editModeState, checkTermConditions: true});
            }
        }, _.noop);

    }, [editModeState, modalApi, renderTermConditionContent, translator]);

    const openDeliveryMethodChangeSuccessPopup = useCallback((deliveryMethod, accountInEditIndex) => {
        const componentProps = {
            title: translator(messages.thankyou),
            message: translator(messages.docDeliveryMethodUpdated)
        };
        modalApi.showModal(<CustomConfirmPopup {...componentProps} />).catch(() => {
            // when user clicked X for the thank you popup
            if (accountNumberEdit) {
                history.push('/userprofile');
            } else {
                resetBackupDeliveryMethod(deliveryMethod, accountInEditIndex);
            }
        });
    }, [accountNumberEdit, history, modalApi, resetBackupDeliveryMethod, translator]);

    const openEmailVerificationPopup = useCallback((deliveryMethod, accountInEditIndex) => {
        const emailComponentProps = {
            deliveryMethod,
            authHeader,
            WniUsersProfileDetailsService
        };
        modalApi.showModal(<EmailVerificationPopup {...emailComponentProps} />).then(() => {
            // when user passed the email verification code
            openDeliveryMethodChangeSuccessPopup(deliveryMethod, accountInEditIndex);
        }).catch(() => {
            // when user clicked X for the email verification popup
            const exitComponentProps = {                       
                title: translator(messages.confirmExit),
                message: translator(messages.confirmExitMsg),
                showButtons: true
            };
            modalApi.showModal(<CustomConfirmPopup {...exitComponentProps} />).then(() => {
                // clicked Continue button in the exit popup
                if (accountNumberEdit) {
                    history.push('/userprofile');
                } else {
                    resetBackupDeliveryMethod(deliveryMethod, accountInEditIndex);
                }
            }).catch(() => {
                // clicked Cancel button in the exit popup
                openEmailVerificationPopup(deliveryMethod, accountInEditIndex);
            });
        });
    }, [authHeader, modalApi, openDeliveryMethodChangeSuccessPopup, translator, accountNumberEdit, history, resetBackupDeliveryMethod]);

    const startAccountEmailVerification = useCallback((accounts) => {
        const accountInEditIndex = accounts.findIndex((act) => accountNumberEdit === _.get(act, 'deliveryMethodVM.value.accountNumber'));
        if (accountInEditIndex !== -1) {
            const account = accounts[accountInEditIndex];
            const deliveryMethod = _.get(account, 'deliveryMethodVM.value');
            if (!deliveryMethod.emailVerified) {
                openEmailVerificationPopup(deliveryMethod, accountInEditIndex);
            } else {
                // if user again clicked the email link after email has already been verified, no need to start email verification process
                history.push('/userprofile');
            }
        }
    }, [accountNumberEdit, history, openEmailVerificationPopup]);

    const getMergedPAYGContacts = useCallback((paygDetailsVM, accountNumber) => {
        const accountPAYGContactVMs = _.filter(paygDetailsVM, (vm) => vm.accountNumber.value === accountNumber);
        const mergedVMs = [];
        const groupedVMs = _.groupBy(accountPAYGContactVMs, (vm) => vm.publicID.value);
        Object.values(groupedVMs).forEach((contactVMsWithSamePublicID) => {
            let emailLabel = [];
            const groupedContactVMs = _.groupBy(contactVMsWithSamePublicID, (vm) => vm.productCode.value);
            Object.entries(groupedContactVMs).forEach(([productCode, contactVMsWithSameProduct]) => {
                const productName = ProductUtil.getProductName(productCode);
                const policyNumbers = _.map(contactVMsWithSameProduct, (vm) => vm.policyNumber.value);
                emailLabel.push(`${productName} ${_.join(policyNumbers, ', ')}`);
            })
            emailLabel = emailLabel.join(' and ');
            mergedVMs.push({
                contactVM: contactVMsWithSamePublicID[0],
                emailLabel: `${translator(messages.emailFor)} ${emailLabel}`
            });
        })
        return mergedVMs;
    }, []);

    useEffect(() => {
        const accountInfoPromise = UsersProfileDetailsService.getAccountsContactSummaries(authHeader);
        const deliveryMethodInfoPromise = WniUsersProfileDetailsService.getAccountsDeliveryMethods(authHeader);
        const paygInfoPromise = WniUsersProfileDetailsService.getAccountsPAYGDetails(authHeader);

        Promise.all([accountInfoPromise, deliveryMethodInfoPromise, paygInfoPromise])
            .then(([accountResult, deliveryMethodResult, paygResult]) => {
                const accountSummaries = _.sortBy(accountResult, 'accountNumber');
                const deliveryMethods = _.sortBy(deliveryMethodResult, 'accountNumber');

                const multiAccount = accountSummaries.length > 1;
                updateIsMultiAccount(multiAccount);

                const accountSummariesVM = accountSummaries.map(
                    (accountInfo) => viewModelService.create(
                        accountInfo,
                        'pc',
                        'edge.capabilities.profileinfo.user.dto.AccountSummaryDTO'
                    )
                );
                const deliveryMethodsVM = deliveryMethods.map(
                    (deliveryMethodInfo) => viewModelService.create(
                        deliveryMethodInfo,
                        'pc',
                        'wni.edge.capabilities.profileinfo.user.dto.AccountDeliveryMethodDTO'
                    )
                );
                const paygDetailsVM = paygResult.map(
                    (paygInfo) => viewModelService.create(
                        paygInfo,
                        'pc',
                        'wni.edge.capabilities.profileinfo.user.dto.PAYGContactDTO'
                    )
                );
                const accounts = accountSummaries.map((accountSummary, index) => {
                    const accountPAYGDetailsVM = getMergedPAYGContacts(paygDetailsVM, accountSummary.accountNumber);
                    return {
                        deliveryMethodVM: deliveryMethodsVM[index],
                        accountSummaryVM: accountSummariesVM[index],
                        accountPAYGDetailsVM
                    };
                });
                updateAccountsDetails({ accounts });
                updateBackupDeliveryMethods(_.cloneDeep(deliveryMethods));
                if (!_.isEmpty(paygResult)) {
                    const newBackupPAYGContacts = [];
                    const groupedResults = _.groupBy(paygResult, (vm) => vm.publicID);
                    Object.entries(groupedResults).forEach(([publicID, results]) => {
                        newBackupPAYGContacts.push({
                            publicID: publicID,
                            emailAddress: results[0].emailAddress
                        });
                    })
                    updateBackupPAYGContacts(newBackupPAYGContacts);
                }
                // if jump from the link in the email, directly go to popup window and start the Email Verification process
                if (accountNumberEdit) {
                    startAccountEmailVerification(accounts);
                }
            })
            .finally(() => updateDisplayLoader(false));
        // Only executed on the first render
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const enterEditMode = useCallback((evt) => {
        const index = parseInt(evt.index, 10);
        updateEditModeState({
            accountInEditIndex: index,
            backupDeliveryMethod: backupDeliveryMethods[index],
            checkTermConditions: false,
            saveBtnClicked: false
        });
    }, [backupDeliveryMethods]);

    const resetEditState = useCallback(() => {
        updateEditModeState({
            accountInEditIndex: NOT_EDIT_MODE_INDEX,
            checkTermConditions: true
        });
    }, []);

    const handleCancel = useCallback(() => {
        modalApi.showConfirm({
            title: messages.cancelEditTitle,
            message: messages.cancelEditMessage,
            status: 'info',
            icon: 'gw-error-outline',
            confirmButtonText: commonMessages.yesModel,
            cancelButtonText: commonMessages.noModel
        }).then((results) => {
            if (results === 'confirm') {
                const {
                    accountInEditIndex,
                    backupDeliveryMethod
                } = editModeState;
                const { accounts } = accountsDetails;
                const accountDetails = accounts[accountInEditIndex];
                const { accountPAYGDetailsVM } = accountDetails;
                accountDetails.deliveryMethodVM.value = _.cloneDeep(backupDeliveryMethod);
                if (!_.isEmpty(accountPAYGDetailsVM)) {
                    accountPAYGDetailsVM.forEach((vm) => {
                        const publicID = _.get(vm, 'contactVM.publicID.value');
                        const bakItem = _.find(backupPAYGContacts, (item) => item.publicID === publicID);
                        _.set(vm, 'contactVM.emailAddress.value', bakItem.emailAddress);
                    });
                }
                updateAccounts({ accounts });
                resetEditState();
            }
        }, _.noop);
    }, [accountsDetails, backupPAYGContacts, editModeState, modalApi, resetEditState, updateAccounts]);

    const accountPAYGDetailsVMValid = useCallback((detailsVM) => {
        return _.every(detailsVM, (item) => item.contactVM.emailAddress.aspects.valid);
    }, []);

    const afterSaveDeliveryDetails = useCallback((deliveryMethod, backupDeliveryMethod, accountInEditIndex) => {
        const { policyHolderPrintPref, email } = deliveryMethod;
        const { email: oldEmail, emailVerified: oldEmailVerified } = backupDeliveryMethod;
        let emailHasVerified = false;
        if (policyHolderPrintPref === 'electronic_portal_delivery') {
            emailHasVerified = !!oldEmailVerified && (email === oldEmail);
        }
        if (policyHolderPrintPref === 'print_mail_delivery' || emailHasVerified) {
            openDeliveryMethodChangeSuccessPopup(deliveryMethod, accountInEditIndex);
        } else if (policyHolderPrintPref === 'electronic_portal_delivery') {
            const componentProps = {
                title: translator(messages.thankyou),
                message: translator(messages.weHaveSentAnEmail)
            };
            modalApi.showModal(<CustomConfirmPopup {...componentProps} />).catch(() => {
                openEmailVerificationPopup(deliveryMethod, accountInEditIndex);
            });
        }
    }, [modalApi, openDeliveryMethodChangeSuccessPopup, openEmailVerificationPopup, translator]);

    const getNewPAYGContacts = useCallback((accountPAYGDetailsVM) => {
        const newPAYGContacts = [];
        backupPAYGContacts.forEach((bakItem) => {
            const updatedItem = _.find(accountPAYGDetailsVM, (vm) => vm.contactVM.publicID.value === bakItem.publicID);
            const newItem = {
                publicID: updatedItem.publicID,
                emailAddress: updatedItem.emailAddress
            };
            newPAYGContacts.push(newItem);
        });
        return newPAYGContacts;
    }, []);

    const updateDeliveryDetails = useCallback(() => {
        const { accountInEditIndex, backupDeliveryMethod, checkTermConditions } = editModeState;
        const { accounts } = accountsDetails;
        const updatedAccountDetails = accounts[accountInEditIndex];
        const { deliveryMethodVM, accountSummaryVM, accountPAYGDetailsVM } = updatedAccountDetails;
        const { policyHolderPrintPref } = deliveryMethodVM.value;
        updateEditModeState({ ...editModeState, saveBtnClicked: true });

        if (_.isEmpty(policyHolderPrintPref) ||
            (policyHolderPrintPref === 'electronic_portal_delivery' && (!checkTermConditions || !deliveryMethodVM.email.aspects.valid))) {
            return;
        }

        if (!_.isEmpty(accountPAYGDetailsVM) && !accountPAYGDetailsVMValid(accountPAYGDetailsVM)) {
            return;
        }

        if (!_.isEmpty(accountPAYGDetailsVM)) {
            const accountNumber = accountSummaryVM.accountNumber.value;
            const paygChanges = getPAYGEmailChangesInForm(accountPAYGDetailsVM, backupPAYGContacts);
            if (!_.isEmpty(paygChanges)) {
                updateDisplayLoader(true);
                WniUsersProfileDetailsService.updatePAYGEmails(
                    accountNumber,
                    paygChanges,
                    authHeader
                ).then(() => {
                     resetEditState();
                    const newPAYGContacts = getNewPAYGContacts();
                    updateBackupPAYGContacts(newPAYGContacts);
                })
                .finally(() => updateDisplayLoader(false));
            }
        }

        if (deliveryMethodChangedInForm(deliveryMethodVM.value, backupDeliveryMethod)) {
            updateDisplayLoader(true);
            const updateDeliveryMethod = WniUsersProfileDetailsService
                .updateAccountDeliveryMethod(
                    deliveryMethodVM.value,
                    authHeader
                );

            updateDeliveryMethod
            .then((updatedDeliveryMethod) => {
                resetEditState();
                afterSaveDeliveryDetails(updatedDeliveryMethod, backupDeliveryMethod, accountInEditIndex);
            })
            .finally(() => updateDisplayLoader(false));
        } else {
            resetEditState();
        }
    }, [editModeState, accountsDetails, accountPAYGDetailsVMValid, backupPAYGContacts, authHeader, getNewPAYGContacts, resetEditState, afterSaveDeliveryDetails]);

    const getAccountTitle = useCallback(
        (accountSummaryVM) => {
            if (isMultiAccount && hasPoliciesInForce(accountSummaryVM)) {
                const accountName = accountSummaryVM.accountContact.value;
                const accountNumber = accountSummaryVM.accountNumber.value;
                return translator(messages.accountTitleWithInForcePolicies, {
                    accountName: accountName.displayName,
                    accountNumber: accountNumber
                });
            }

            if (isMultiAccount && !hasPoliciesInForce(accountSummaryVM)) {
                return translator(messages.accountTitleWithNoInForcePolicies);
            }

            return translator(messages.accountDetails);
        },
        [isMultiAccount, translator]
    );

    const isReadOnly = useCallback((index) => editModeState.accountInEditIndex !== index, [
        editModeState.accountInEditIndex
    ]);

    const handleEmailChange = useCallback((value) => {
        const {
            accountInEditIndex,
            backupDeliveryMethod
        } = editModeState;
        const { email: oldEmail, emailVerified: oldEmailVerified } = backupDeliveryMethod;
        const { accounts } = accountsDetails;
        const deliveryMethod = accounts[accountInEditIndex].deliveryMethodVM.value;
        _.set(deliveryMethod, 'email', value);
        _.set(deliveryMethod, 'emailVerified', value === oldEmail ? oldEmailVerified : false);
        accounts[accountInEditIndex].deliveryMethodVM.value = _.cloneDeep(deliveryMethod);
        updateAccounts({ accounts });
    }, [accountsDetails, editModeState, updateAccounts]);

    const handlePAYGEmailChange = useCallback((value, publicID) => {
        const {
            accountInEditIndex
        } = editModeState;
        const { accounts } = accountsDetails;
        const accountDetails = accounts[accountInEditIndex];
        const { accountPAYGDetailsVM } = accountDetails;
        const selectedPAYGContactVM = _.find(accountPAYGDetailsVM, (vm) => vm.contactVM.publicID.value === publicID);
        _.set(selectedPAYGContactVM, 'contactVM.emailAddress', value);
        updateAccounts({ accounts });
    }, [accountsDetails, editModeState, updateAccounts]);

    const getTermsAndConditionsCheckupLabel = useCallback(() => {
        return (
            <>
                <span>{translator(messages.doYouAgree)}
                    <Link 
                        id="termsAndConditionsCheckupLink"
                        onClick={() => openTermsAndConditionsPopup()}
                        style={{display: 'inline'}}>
                        {translator(messages.termsAndConditions)}
                    </Link>?
                </span><br/>
                <span>{translator(messages.clickTheLink)}</span>
            </>
        )
    }, [translator, openTermsAndConditionsPopup]);

    const handleTermsAndConditions = useCallback((value) => {
        updateEditModeState({ ...editModeState, checkTermConditions: value});
    }, [editModeState]);

    const translateYesNo = useCallback((field) => {
        return field === true ? translator(messages.infoYes) : translator(messages.infoNo)
    }, [translator]);

    const getLabelWithTooltip = (label, tooltip) => {
        return (
            <div className={styles.labelWithTooltipStyle}>
                {translator(label)}
                <TooltipIcon
                    id="emailVerifiedtooltipIcon"
                    text={translator(tooltip)}
                />
            </div>
        )
    }

    const generatedProps = useMemo(() => {
        const { accounts = [] } = accountsDetails;

        const deliveryMethodAvailableValue = [
            {
                code: "print_mail_delivery",
                name: isPhone ? translator(messages.phoneWidePrintMailDelivery) : translator(messages.printMailDelivery)
            },
            {
                code: "electronic_portal_delivery",
                name: isPhone ? translator(messages.phoneWideElectronicPortalDelivery) : translator(messages.electronicPortalDelivery)
            }
        ]
        const overrides = accounts.map(({ accountSummaryVM, deliveryMethodVM, accountPAYGDetailsVM }, index) => {
            const { checkTermConditions, saveBtnClicked } = editModeState;
            const { policyHolderPrintPref } = deliveryMethodVM.value;
            return {
                [`accountItemContainer${index}`]: {
                    className:
                        isReadOnly(index)
                        && editModeState.accountInEditIndex !== NOT_EDIT_MODE_INDEX
                            ? styles.accountSectionReadonly
                            : styles.accountSummaryContainer
                },
                [`titleAccount${index}`]: {
                    content: getAccountTitle(accountSummaryVM)
                },
                [`accountContactContainer${index}`]: {
                    readOnly: true
                },
                [`editButton${index}`]: {
                    visible: editModeState.accountInEditIndex === NOT_EDIT_MODE_INDEX
                },
                [`deliveryMethod${index}`]: {
                    readOnly: isReadOnly(index),
                    availableValues: deliveryMethodAvailableValue
                },
                [`email${index}`]: {
                    readOnly: isReadOnly(index),
                    visible: policyHolderPrintPref === 'electronic_portal_delivery',
                    onValueChange: handleEmailChange
                },
                [`emailVerifiedYesNo${index}`]: {
                    visible: policyHolderPrintPref === 'electronic_portal_delivery',
                    value: translateYesNo(deliveryMethodVM.value.emailVerified),
                    label: getLabelWithTooltip(messages.emailVerifiedLabel, messages.emailVerifiedTooltip),
                },
                [`actionsContainer${index}`]: {
                    visible: !isReadOnly(index)
                },
                [`termsAndConditionsCheckupId${index}`]: {
                    disabled: true,
                    visible: policyHolderPrintPref === 'electronic_portal_delivery',
                    label: getTermsAndConditionsCheckupLabel(),
                    value: checkTermConditions
                },
                [`termsAndConditionsErrorMsg${index}`]: {
                    visible: policyHolderPrintPref === 'electronic_portal_delivery' && !checkTermConditions && !!saveBtnClicked
                },
                [`paygEmailContainer${index}`]: {
                    visible: !_.isEmpty(accountPAYGDetailsVM)
                },
                [`paygEmailComponent${index}`]: {
                    index,
                    isReadOnly,
                    accountPAYGDetailsVM,
                    handlePAYGEmailChange,
                    onValidate
                }
            };
        });
        return Object.assign({}, ...overrides);
    }, [accountsDetails, isPhone, translator, editModeState, isReadOnly, getAccountTitle, handleEmailChange, translateYesNo, getTermsAndConditionsCheckupLabel, handlePAYGEmailChange, onValidate]);

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showOptional: false,
            phonewide: {
                labelPosition: 'top',
            },
            phone: {
                labelPosition: 'top',
            }
        },
        ...generatedProps
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            enterEditMode,
            handleCancel,
            updateDeliveryDetails,
            onValidate,
            handleTermsAndConditions
        },
        resolveComponentMap: {
            userdetailscomponent: UserDetailsComponent,
            paygemailscomponent: PAYGEmailsComponent
        }
    };

    if (displayLoader) {
        return <Loader />;
    }

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={accountsDetails}
            overrideProps={overrideProps}
            onModelChange={updateAccounts}
            onValidationChange={onValidate}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

export default withRouter(withViewModelService(UsersProfile));
