import { defineMessages } from 'react-intl';

export default defineMessages({
    addVehicle: {
        id: 'fnol.pa.directives.policy.addVehicle',
        defaultMessage: 'Add Another Vehicle'
    },
    selectVehicle: {
        id: 'fnol.pa.directives.policy.Select a Vehicle',
        defaultMessage: 'Select a Vehicle'
    },
    year: {
        id: 'fnol.pa.directives.policy.Year',
        defaultMessage: 'Year'
    },
    make: {
        id: 'fnol.pa.directives.policy.Make',
        defaultMessage: 'Make'
    },
    model: {
        id: 'fnol.pa.directives.policy.Model',
        defaultMessage: 'Model'
    },
    vin: {
        id: 'fnol.pa.directives.policy.VIN Number',
        defaultMessage: 'VIN Number'
    },
    location: {
        id: 'fnol.pa.directives.policy.Location',
        defaultMessage: 'Location'
    },
    removeIncidentTitle: {
        id: 'fnol.pa.directives.policy.deleteTitle',
        defaultMessage: 'Want to Delete?',
    },
    removeIncident: {
        id: 'fnol.pa.directives.policy.Are you sure you want to delete this record?',
        defaultMessage: 'Are you sure you want to delete this record?'
    },
    vehicleInvolved: {
        id: 'fnol.pa.directives.policy.Any other {pageTypeName} involved?',
        defaultMessage: 'Any other {pageTypeName} involved?'
    },
    insuredButtonText: {
        id: 'fnol.pa.directives.policy.Add Another {pageTypeName}',
        defaultMessage: 'Add Another {pageTypeName}'
    },
    insuredButtonTip: {
        id: 'fnol.pa.directives.policy.If the vehicle you were driving is not found above, please click button Add Another {pageTypeName}',
        defaultMessage: 'If the {pageType} you were driving is not found above, please click button \'Add Another {pageTypeName}\''
    },
    thirdButtonText: {
        id: 'fnol.pa.directives.policy.Add Thrid Party {pageTypeName}',
        defaultMessage: 'Add Thrid Party {pageTypeName}'
    },
    thirdButtonTip: {
        id: 'fnol.pa.directives.policy.If third party involved, please click button Add Thrid Party pageTypeName}',
        defaultMessage: 'If third party involved, please click button \'Add Thrid Party {pageTypeName}\''
    },
    itemToBeInsured: {
        id: 'fnol.pa.directives.policy.Item to be Insured',
        defaultMessage: 'Item to be Insured'
    },
    WatercraftInvolvedType: {
        id: 'fnol.pa.directives.policy.Watercraft/Unit Involved Type',
        defaultMessage: 'Watercraft/Unit Involved Type'
    },
    invalidSelectionTitle: {
        id: 'fnol.pa.directives.policy.Invalid Selection',
        defaultMessage: 'Invalid Selection'
    },
    invalidSelectionText: {
        id: 'fnol.pa.directives.policy.Sorry, you cannont select the same person as both the driver and passenger for this car. \n\n Please choose different individuals for the driver and passenger roles before saving.',
        defaultMessage: 'Sorry, you cannont select the same person as both the driver and passenger for this car. \n\n Please choose different individuals for the driver and passenger roles before saving.'
    },
    selectWatercraftDriverTitle: {
        id: 'fnol.pa.directives.policy.Was anyone operating the watercraft?',
        defaultMessage: 'Was anyone operating the watercraft?'
    },
    damageToWatercraft: {
        id: 'fnol.pa.directives.policy.Briefly describe the damage to unit(s)',
        defaultMessage: 'Briefly describe the damage to unit(s)'
    },
    anyoneInjured: {
        id: 'fnol.pa.directives.policy.Was anyone injured?',
        defaultMessage: 'Was anyone injured?'
    },
    injuredPerson: {
        id: 'fnol.pa.directives.policy.Injured Person(s)',
        defaultMessage: 'Injured Person(s)'
    },
    AddInjuredPerson: {
        id: 'fnol.pa.directives.policy.Add Injured Person',
        defaultMessage: 'Add Injured Person'
    },
    unknowTips: {
        id: 'fnol.pa.directives.policy.unknowTips',
        defaultMessage: 'If you don\'t know specific {what}, please enter \'Unknown\''
    },
    Edit: {
        id: 'fnol.pa.directives.policy.thirdParty.Edit',
        defaultMessage: 'Edit'
    },
    Delete: {
        id: 'fnol.pa.directives.policy.thirdParty.Delete',
        defaultMessage: 'Delete'
    },
    DamageToVehicle: {
        id: 'fnol.pa.directives.policy.Damage to Vehicle',
        defaultMessage: 'Damage to Vehicle'
    },
    Damage: {
        id: 'fnol.pa.directives.policy.thirdParty.Damage',
        defaultMessage: 'Damage'
    },
    BrieflyDescOfDamage: {
        id: 'fnol.pa.directives.policy.Briefly describe the damage to vehicle',
        defaultMessage: 'Briefly describe the damage to vehicle'
    },
    DescOfDamage: {
        id: 'fnol.pa.directives.policy.thirdParty.Damage Description',
        defaultMessage: 'Damage Description'
    },
    InjuredPerson: {
        id: 'fnol.pa.directives.policy.Injured Person(s)',
        defaultMessage: 'Injured Person(s)'
    },
    AddInjuries: {
        id: 'fnol.pa.directives.policy.Add Injuries',
        defaultMessage: 'Add Injuries'
    },
});
