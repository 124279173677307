import React, {useState} from "react"
import _ from "lodash";
import { DropdownMenuButton, ToggleField, Button } from "@jutro/components";
import { DisplayColumn, Table } from '@jutro/legacy/datatable';
import { DropdownMenuLink } from '@jutro/router';
import { useTranslator } from '@jutro/locale';
import SingleVehicleIncident from "./SingleVehicleIncident/SingleVehicleIncident";
import messages from './VehicleIncidents.messages'
import { getNewContactTempId } from "../util/NewContactUtil";
import { getContactDisplayName } from "../util/ContactUtil";
import { getNewAddressTempId } from "../util/NewAddressUtil";
import { getFullAddressDisplayName } from "../util/AddressUtil";

const VehicleIncidentQuestionValue = {
    Yes: 'Yes',
    No: 'No'
}

const vehicleIncidentInitValue = {
}

const VehicleIncidents = (props) => {
    const {
        vehicleIncidents = [],
        updateVehicleIncidents,
        ownerContacts,
        predefinedLossLocations,
        incidentsAddresses,
    } = props;

    const translator = useTranslator();

    const [vehicleIncidentQuestionValue, setVehicleIncidentQuestion] = useState(() => {
        return vehicleIncidents.length > 0 ? VehicleIncidentQuestionValue.Yes : VehicleIncidentQuestionValue.No
    })
    const [singleVehicleIncidentPopupStatus, setSingleVehicleIncidentPopupStatus] = useState({
        isOpen: false,
        originVehicleIncident: vehicleIncidentInitValue,
        editingVehicleIncidentIndex: undefined
    })

    const closePopup = () => {
        setSingleVehicleIncidentPopupStatus({
            isOpen: false,
            originVehicleIncident: vehicleIncidentInitValue,
            editingVehicleIncidentIndex: undefined
        })
    }

    const onPopupCancel = () => {
        closePopup()
    }

    const onPopupSave = (vehicleIncident, editingVehicleIncidentIndex, vehicleOwner, vehicleLocation) => {

        const isNewContact = !ownerContacts.some((contact) => contact.publicID === vehicleOwner.publicID)
        const isClaimAddress = predefinedLossLocations.some((address) => address.publicID === vehicleLocation.publicID)
        const isNewIncidentAddress = !isClaimAddress && !incidentsAddresses.some((address) => address.publicID === vehicleLocation.publicID)

        let updatedVehicleIncident = vehicleIncident
        let updatedVehicleOwner = vehicleOwner
        if (isNewContact) {
            const tempContactID = getNewContactTempId()
            updatedVehicleIncident = {...updatedVehicleIncident, vehicleOwnerContactPublicID: tempContactID}
            updatedVehicleOwner = {...updatedVehicleOwner, publicID: tempContactID}
        }

        let updatedVehicleLocation = vehicleLocation
        if (isNewIncidentAddress) {
            const tempAddressID = getNewAddressTempId()
            updatedVehicleIncident = {...updatedVehicleIncident, vehicleLocationPublicID: tempAddressID}
            updatedVehicleLocation = {...updatedVehicleLocation, publicID: tempAddressID}
        }

        let updatedVehicleIncidents
        if (_.isNil(editingVehicleIncidentIndex)) {
            // new added item
            updatedVehicleIncidents = vehicleIncidents.concat([updatedVehicleIncident])
        } else {
            // existing item
            updatedVehicleIncidents = vehicleIncidents.toSpliced(editingVehicleIncidentIndex, 1, updatedVehicleIncident)
        }
        if (isClaimAddress) {
            updateVehicleIncidents(updatedVehicleIncidents, updatedVehicleOwner)
        } else {
            updateVehicleIncidents(updatedVehicleIncidents, updatedVehicleOwner, updatedVehicleLocation)
        }
        closePopup()
    }

    return <>
        <ToggleField
            id='vehicleIncidentQuestion'
            label={translator(messages.VehicleIncidentsQuestion)}
            labelPosition='left'
            availableValues={[
                {
                    code: VehicleIncidentQuestionValue.Yes,
                    name: translator(messages.Yes)
                },
                {
                    code: VehicleIncidentQuestionValue.No,
                    name: translator(messages.No)
                }
            ]}
            onValueChange={(value) => {
                setVehicleIncidentQuestion(value)
            }}
            disabled={vehicleIncidents.length > 0}
            value={vehicleIncidentQuestionValue}
        />
        {vehicleIncidentQuestionValue === VehicleIncidentQuestionValue.Yes &&
            <>
                <Table
                    data={vehicleIncidents.map((e, index) => ({...e, number: index + 1}))}
                    titlePosition='left'
                >
                    <DisplayColumn
                        id="vehicleSectionIDColumn"
                        header={translator(messages.Number)}
                        path="number"
                        textAlign='center'
                    />
                    <DisplayColumn
                        id="vehicleOwnerColumn"
                        header={translator(messages.VehicleOwner)}
                        textAlign='center'
                        renderCell={(item) => {
                            const {
                                vehicleOwnerContactPublicID
                            } = item
                            const vehicleOwner = ownerContacts.find((contact) => contact.publicID === vehicleOwnerContactPublicID)
                            return getContactDisplayName(vehicleOwner)
                        }}
                    />
                    <DisplayColumn
                        id="vehicleLocationColumn"
                        header={translator(messages.VehicleLocation)}
                        textAlign='center'
                        renderCell = {(item) => {
                            const addressPublicID = _.get(item, 'vehicleLocationPublicID')
                            const allAddresses = predefinedLossLocations.concat(incidentsAddresses)
                            const matchedAddress = allAddresses.find((address) => address.publicID === addressPublicID)
                            return getFullAddressDisplayName(matchedAddress)
                        }}
                    />
                    <DisplayColumn
                        id="actionColumn"
                        textAlign='right'
                        renderCell={(item, index) => {
                            return <DropdownMenuButton
                                id={`DropdownMenuBtn_${index}`}
                                icon="gw-expand-more"
                                size="small"
                                iconPosition="right"
                                disabled={singleVehicleIncidentPopupStatus.isOpen}
                            >
                                <DropdownMenuLink
                                    icon="gw-edit"
                                    iconPosition="left"
                                    onClick={() => setSingleVehicleIncidentPopupStatus({
                                        isOpen: true,
                                        originVehicleIncident: item,
                                        editingVehicleIncidentIndex: index
                                    })}
                                >
                                    {translator(messages.Edit)}
                                </DropdownMenuLink>
                                <DropdownMenuLink
                                    icon="gw-delete"
                                    iconPosition="left"
                                    onClick={() => {
                                        const updatedInjuredIncidents = vehicleIncidents.toSpliced(index, 1)
                                        updateVehicleIncidents(updatedInjuredIncidents)
                                    }}
                                >
                                    {translator(messages.Delete)}
                                </DropdownMenuLink>
                            </DropdownMenuButton>
                        }}
                    />
                </Table>
                <Button
                    variant="secondary" 
                    label={translator(messages.AddVehicle)}
                    onClick={() => setSingleVehicleIncidentPopupStatus({
                        isOpen: true,
                        originVehicleIncident: vehicleIncidentInitValue
                    })}
                    disabled={singleVehicleIncidentPopupStatus.isOpen}
                />
                {singleVehicleIncidentPopupStatus.isOpen && <SingleVehicleIncident
                    originVehicleIncident={singleVehicleIncidentPopupStatus.originVehicleIncident}
                    editingVehicleIncidentIndex={singleVehicleIncidentPopupStatus.editingVehicleIncidentIndex}
                    onPopupCancel={onPopupCancel}
                    onPopupSave={onPopupSave}
                    ownerContacts={ownerContacts}
                    predefinedLossLocations={predefinedLossLocations}
                    incidentsAddresses={incidentsAddresses}
                />}
            </>
        }
        
    </>
}

export default VehicleIncidents
