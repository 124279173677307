import _ from 'lodash';

const getDetailInputDTOs = (premiumReportDetails) => {
    const auditedBasisDTOs = [];
    premiumReportDetails.forEach((prd) => {
        const { ratingPeriods } = prd;
        ratingPeriods.forEach((rp) => {
            const { detailItems } = rp;
            detailItems.forEach((item) => {
                const auditedBasisDTO = {
                    covEmpPublicID: item.covEmpPublicID,
                    actual: item.actual,
                    actualUSLH: item.actualUSLH,
                    hasUSLH: item.hasUSLH
                };
                auditedBasisDTOs.push(auditedBasisDTO);
            })
        })
    })
    return auditedBasisDTOs;
};

const inputHasError = (premiumReportDetails) => {
    let flag = false;
    for (let i = 0; i < premiumReportDetails.length; i += 1) {
        const { ratingPeriods } = premiumReportDetails[i];
        for (let j = 0; j < ratingPeriods.length; j += 1) {
            const { detailItems } = ratingPeriods[j]; 
            if (_.some(detailItems, (item) => _.isNil(item.actual) || (item.hasUSLH && _.isNil(item.actualUSLH)))) {
                flag = true;
                break;
            }
        }
        if (flag) {
            break;
        }
    }
    return flag;
};

const hasUSLHItem= (ratingPeriod) => {
    const { detailItems } = ratingPeriod;
    return !_.isEmpty(_.find(detailItems, (item) => item.hasUSLH));
};

const getCostsData = (premiumReportDetails, totalPremium) => {
    const costsData = [];
    let amountForSelectedCodes = 0;
    premiumReportDetails.forEach((prd) => {
        const { ratingPeriods } = prd;
        ratingPeriods.forEach((rp) => {
            const { costItems } = rp;
            costItems.forEach((item) => {
                costsData.push(item);
                amountForSelectedCodes += item.premium.amount;
            })
        })
    })
    const sortedCostsData = _.sortBy(costsData, ['locationDesc', 'classCode', 'type']);
    const otherPremiumDesc = 'Other Premium, Fees and Surcharges';
    const otherPremium = {
        amount: totalPremium.amount - amountForSelectedCodes,
        currency: totalPremium.currency
    }
    const otherPremiumItem = {
        locationDesc: otherPremiumDesc,
        premium: otherPremium
    }
    sortedCostsData.push(otherPremiumItem);
    return sortedCostsData;
};

export {
	getDetailInputDTOs,
    hasUSLHItem,
    getCostsData,
    inputHasError
};